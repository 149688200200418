@import "../layouts/layout1.scss";
.add-edit-product-popup {
  background: transparent linear-gradient(180deg, #3a3630e0 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  z-index: 999999 !important;
  div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #0000000d;
    border-radius: 20px;
    padding: 25px 15px;
    form {
      margin: 0px;
    }
    h2.MuiTypography-root.MuiTypography-h6 {
      display: flex;
      justify-content: space-between;
      span {
        text-align: left;
        font: normal normal 950 20px/19px Eveleth;
        letter-spacing: 0px;
        color: #3a3630;
        opacity: 0.65;
      }
      button.close-icon {
        padding: 0px;
        .MuiIconButton-label {
          // background: #000000;
          position: absolute;
          right: 10px;
          svg {
            height: 40px;
            width: 40px;
            path {
              fill: #000000;
            }
          }
        }
        &:hover {
          .MuiIconButton-label {
            opacity: 1;
          }
        }
      }
    }
    .code-title-image {
      &.mobile {
        display: none;
      }
      width: 50%;
      // display: flex;
      position: relative;
      float: left;
      .code-title:first-child {
        margin-bottom: 20px;
      }
      .image-div {
        align-items: stretch;
        display: flex;
        padding-left: 20px;
        max-height: 150px;
        width: 100%;
      }
      .drug-drop {
        position: absolute;
        height: calc(100% - 40px);
        width: calc(100% - 60px);
        margin: 20px;
        border: 4px dashed #b9b9b8;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        opacity: 0.7;
        svg {
          path {
            fill: red;
          }
        }
        label {
          font-size: 16px;
          color: red;
          text-align: center;
        }
      }
      .image-input {
        cursor: pointer;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        opacity: 0;
      }
    }
    h6.MuiTypography-root {
      text-align: left;
      font: normal normal bold 12px/19px Gotham;
      letter-spacing: 0px;
      color: #3a3630;
      text-transform: uppercase;
      opacity: 0.45;
      margin-bottom: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      svg {
        color: red;
        height: 16px;
        width: 16px;
        margin-left: 4px;
      }
      &.boxed-dimensions-title {
        font: normal normal 950 14px/19px Eveleth;
      }
      &.assembly-allowed {
        justify-content: space-between;
      }
      .small {
        text-transform: lowercase !important;
        font-size: 11px;
        a {
          color: #3a3630;
          text-decoration: underline;
        }
      }
    }
    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $blackColor !important;
      }
    }
    .boxed-dimension {
      background: #f6f6f4 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      display: flex;
      justify-content: space-evenly;
      padding: 20px 0px;
      .height-width-depth {
        width: 18%;
        float: left;
        &:last-child {
          width: 24%;
        }
        div.MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
          background: #ffffff !important;
          .MuiOutlinedInput-adornedEnd {
            padding-right: 0px;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
        }
      }
    }
    .assembly-allows {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      .currently-turned-off {
        margin-bottom: 16px;
        text-align: right;
        font: normal normal medium 14px/15px Gotham;
        letter-spacing: 0px;
        color: #e95252;
        text-transform: uppercase;
        opacity: 1;
        svg {
          margin-right: 10px;
          path {
            fill: #e95252;
          }
        }
      }
      .small {
        text-align: right;
        // text-decoration: underline;
        font: normal normal normal 11px Gotham !important;
        letter-spacing: 0px;
        color: #000000;
        a {
          cursor: pointer;
        }
      }
    }
    .MuiDialogActions-spacing {
      align-items: center;
      justify-content: space-between;
      padding: 0 !important;
      margin-top: 30px !important;
      white-space: nowrap;
      margin-bottom: 00px !important;
      button {
        background: #3a3630 0% 0% no-repeat padding-box;
        border-radius: 66px;
        &:hover {
          .MuiButton-label {
            color: #3a3630 !important;
          }
          background: $primaryColor;
        }
        .MuiButton-label {
          text-align: center;
          font: normal normal normal 12px Eveleth !important;
          letter-spacing: 0px !important;
          color: #ffffff !important;
          opacity: 1 !important;
          padding: 10px 30px;
        }
        border-radius: 50px !important;
        &.button-cancel {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid $blackColor;
          opacity: 0.5;
          transition: 1s opacity;
          &:hover {
            opacity: 1;
          }
          .MuiButton-label {
            color: $blackColor !important;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $medium) {
  .add-edit-product-popup {
    .add-edit-grid {
      display: block !important;
    }
    .code-title-image {
      .drug-drop {
        width: calc(100% - 40px) !important;
      }
      width: 100% !important;
      .image-div {
        padding-left: 0 !important;
        // margin-bottom: 20px !important;
      }
      margin-bottom: 20px !important;
      &.mobile {
        display: block !important;
      }
      &.desktop {
        display: none !important;
      }
      width: 50%;
    }
    div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper
      .boxed-dimension {
      display: block;
      float: left;
      .height-width-depth {
        margin: 0px 20px;
        width: calc(50% - 40px) !important;
      }
    }
  }
}
