@import "../layouts/layout1.scss";
#product-page {
  margin: 0px !important;
  width: 100% !important;
  float: left !important;
  .pageTitle {
    font-family: $FontFamilyEveleth;
    color: #3a3630 !important;
  }
  .search-box-grid {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }
  .buttons-add-export-import {
    display: flex;
    align-items: center;
    .separator {
      width: 1px;
      background: #ddd0bd70;
      height: 100%;
      margin: 0px 10px;
    }
    button {
      background: #3a3630 0% 0% no-repeat padding-box;
      border-radius: 66px !important;
      margin: 0px 5px !important;
      box-shadow: 0 0 0 !important;
      padding: 6px 20px;
      &.import-export {
        background: #615e59 0% 0% no-repeat padding-box;
      }
      &.export {
        .MuiButton-startIcon {
          top: -4px;
        }
      }
      &.import {
        .MuiButton-startIcon {
          bottom: -4px;
        }
      }
      &.add-product {
        .MuiButton-startIcon {
          top: -4px;
        }
      }
      span {
        font-family: Gotham;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: initial;
        font-size: 12px;
        position: relative;
        &.MuiButton-label {
          padding: 0px 0px 0px 20px;
          line-height: 1.5;
          .MuiButton-startIcon {
            position: absolute;
            left: 0px;
          }
        }
      }
      svg {
        height: 22px !important;
      }
      &:hover {
        box-shadow: 0 0 0 !important;
        background: $primaryColor;
        span {
          color: $blackColor;
        }
        svg {
          path {
            fill: $blackColor;
            &.arrow {
              fill: #ffffff;
            }
          }
        }
        &.import-export {
          svg {
            path {
              fill: $primaryColor;
              &.arrow {
                fill: #6396e5;
              }
              &.main {
                fill: $blackColor;
              }
            }
          }
        }
      }
    }
  }
  .quick-filter {
    background: #d7d6d530 0% 0% no-repeat padding-box !important;
    border-radius: 6px !important;
    // opacity: 0.2!important;
    padding: 6px 12px 6px 12px !important;
    margin: 6px !important;
    .quick-filter-md-9 {
      margin-top: 0px !important;
      .MuiFormControlLabel-root {
        margin-left: 16px !important;
        &:nth-of-type(1) {
          margin-left: 0px !important;
        }
        .MuiFormControlLabel-label {
          font-family: Gotham !important;
          margin-left: 5px !important;
          &:nth-of-type(1) {
            color: #3a3630 !important;
          }
        }
      }
      .quick-filter-checkbox {
        margin-left: 16px !important;
        label {
          margin-left: 5px !important;
          font-family: Gotham !important;
          font-size: 14px !important;
          cursor: pointer;
          color: #3a3630;
        }
      }
    }
    .quick-filter-md-3 {
      .MuiFormGroup-root.MuiFormGroup-row {
        display: flex !important;
        align-items: center !important;
        .per-page {
          // display: flex;
          // align-items: center;
          font-family: Gotham !important;
          font-size: 12px;
        }
        .PageNo {
          margin: 7px 14px !important;
          .MuiSelect-outlined.MuiSelect-outlined {
            padding: 5px 30px 5px 10px !important;
          }
          &.sorting-select {
            width: 200px !important;
            font-family: Gotham;
            .MuiSelect-root.MuiSelect-select {
              display: flex;
              justify-content: space-between;
            }
          }
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border: 1px solid gray !important;
          outline: 0px solid gray !important;
        }
      }
    }
  }
  .count-pagination-searched-result {
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    color: #615e59 !important;
    .pagination {
      display: flex;
      justify-content: flex-end;
      font-family: "Gotham";
    }
    .record-count {
      font-family: $FontFamilyGotham;
      font-size: 12px !important;
      float: left !important;
      margin-right: 10px;
    }
    .btn-clear {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
      cursor: pointer;
      text-decoration: underline !important;
      opacity: 0.5;
      &:hover {
        text-decoration: none !important;
      }
      .MuiButton-label {
        text-transform: capitalize !important;
      }
    }
    .btn-search-value {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
    }
  }
  .product-table {
    thead {
      tr {
        th {
          font-family: Gotham-Bold !important;
          font-size: 12px !important;
          padding: 9px 12px !important;
          white-space: nowrap !important;
          background: #615e59 0% 0% no-repeat padding-box;
          color: white !important;
          &:nth-last-child(1) {
            border-radius: 0px 6px 0px 0px !important;
          }
          &:nth-child(1) {
            border-radius: 6px 0px 0px 0px !important;
          }
          .created-icon {
            margin-top: 3px;
            margin-left: 5px !important;
          }
          &:last-child {
            text-align: end;
            button {
              font-family: Gotham;
              font-size: 14px !important;
              color: #ffffff;
              text-transform: capitalize;
              .MuiButton-label span {
                opacity: 0.5;
                text-decoration: underline;
              }
              svg {
                margin-right: 10px;
              }
              &:hover {
                .MuiButton-label span {
                  opacity: 1 !important;
                }
              }
            }
          }
        }
      }
    }
    tbody {
      border: 1px solid gray !important;
      tr {
        &.active,
        &:hover {
          background: #615e5910 0% 0% no-repeat padding-box;
        }
        // &.checked {
        //     background: #615e5910 0% 0% no-repeat padding-box;
        // }
        td {
          font-family: Gotham !important;
          font-size: 12px !important;
          padding: 12px 12px !important;
          &:nth-child(1) {
            border-right: 1px solid rgba(224, 224, 224, 1);
            border-left: 1px solid rgba(224, 224, 224, 1);
          }
          &:last-child {
            border-right: 1px solid rgba(224, 224, 224, 1);
            div {
              justify-content: flex-end;
            }
          }
          &.image-td {
            img {
              max-height: 150px;
              width: 125px;
              // height: 150px;
            }
          }
          &.assembly-build {
            svg {
              height: 12px;
            }
          }
          &.description {
            white-space: break-spaces;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // max-width: 30ch;
          }
          // &:nth-child(2),
          // &:nth-child(3),
          // &:nth-child(5),
          // &:nth-child(6),
          // &:nth-child(4) {
          // }
          .shopify-icon {
            height: 24px;
            width: 24px;
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            &.active {
              -webkit-filter: grayscale(0%);
              filter: grayscale(0%);
            }
          }
          white-space: nowrap;
          // .action-bar {
          // }
          &.order-action {
            // padding: 0px 12px!important;
            div {
              display: flex;
              justify-content: flex-end;
              .more-info {
                margin-right: 10px;
                white-space: nowrap;
                span {
                  font: normal normal normal 10px/14px Eveleth !important;
                  line-height: 1 !important;
                }
                letter-spacing: 0px;
                color: #888075;
                opacity: 1;
                padding: 12px 20px;
                background: #f7f7f7 0% 0% no-repeat padding-box;
                border: 1px solid #ebebeb;
                border-radius: 66px !important;
                opacity: 1;
                &:hover {
                  background: $primaryColor;
                  color: $blackColor;
                }
              }
            }
          }
          &.address {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 30ch;
          }
          .dimensions {
            tr {
              &:hover {
                background: transparent !important;
              }
              th {
                text-align: left;
                font: normal normal normal 14px/15px Gotham;
                letter-spacing: 0px;
                color: #3a3630;
                opacity: 0.5;
              }
              td {
                padding: 0px 10px !important;
                &:last-child {
                  border-right: 0px solid #e0e0e0;
                }
              }
            }
          }
        }
      }
    }
  }
  .count-pagination-searched-result {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    color: #615e59 !important;
    .pagination {
      display: flex;
      justify-content: flex-end;
      font-family: "Gotham";
    }
    .record-count {
      font-family: $FontFamilyGotham;
      font-size: 12px !important;
      float: left !important;
      margin-right: 10px;
      margin-top: 0;
    }
    .btn-clear {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
      cursor: pointer;
      text-decoration: underline !important;
      opacity: 0.5;
      &:hover {
        text-decoration: none !important;
      }
      .MuiButton-label {
        text-transform: capitalize !important;
      }
    }
    .btn-search-value {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
    }
  }
  .multi-order-action {
    z-index: 999;
    display: flex;
    background: #807e7a;
    position: fixed;
    bottom: 0;
    margin-right: -8px;
    margin-left: -8px;
    align-items: center;
    padding: 0px 0px 10px 0 !important;
    border-top: 1px solid #f0f0f0;
    float: left;
    width: calc(100% - 180px);
    .record-count {
      font-size: 12px !important;
      color: #ffffff;
      span {
        color: $primaryColor;
      }
    }
    .btn-clear {
      font-size: 12px !important;
      color: #ffffff !important;
      opacity: 0.5 !important;
    }
    .order-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .order-action-text {
        text-align: right;
        font: normal normal normal 12px Gotham;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.75;
      }
      .order-action-button {
        background: #3a3630 0% 0% no-repeat padding-box;
        border-radius: 66px !important;
        opacity: 1;
        span {
          font: normal normal normal 12px Gotham;
          color: #ffffff;
        }
        padding: 10px 16px;
        margin-left: 8px;
        svg {
          margin-right: 10px;
          width: 12px;
          height: 12px;
        }
        &.delete {
          background: #e95252 0% 0% no-repeat padding-box;
        }
        &.cancel {
          background: #dc6988 0% 0% no-repeat padding-box;
        }
      }
    }
  }
}

.product-edit-delete-dropdown {
  ul {
    li {
      // border-bottom: 1px solid rgba(0, 0, 0, 0.11)!important;
      &.delete-product {
        color: #e95252;
        path {
          fill: #e95252;
        }
      }
      .MuiListItemIcon-root {
        svg {
          height: 20px !important;
          width: 20px !important;
        }
      }
    }
  }
}

@media screen and (max-width: $medium) {
  .import-csv-product {
    .MuiDialog-paperScrollPaper {
      max-height: 250px !important;
    }
  }
  #product-page {
    .search-box-grid {
      display: none !important;
    }
    .multi-order-action {
      width: 100% !important;
      flex-wrap: nowrap;
    }
    .quick-filter {
      @media screen and (max-width: $medium) {
        .quick-filter-md-9 .MuiFormControlLabel-root:nth-of-type(1),
        .quick-filter-checkbox {
          white-space: nowrap;
          float: left;
        }
      }
      .quick-filter-md-9 {
        display: block !important;
        width: 100%;
        float: left;
      }
      .quick-filter-md-3 {
        svg {
          display: none;
        }
        .MuiFormGroup-root {
          .MuiFormGroup-row {
            .PageNo {
              margin: 7px 0px !important;
            }
          }
        }
      }
    }
    .buttons-add-export-import {
      display: block !important;
      text-align: center;
      .separator {
        display: none;
      }
      .import-export-div {
        margin-top: 16px;
      }
    }
  }
}
