@import "../layouts/layout1.scss";
#help-page {
  .help-center {
    min-height: 300px;
    background-image: url("../../assets/img/shopifyHelpCentre_featureBackground.jpg");
    text-align: center;
    padding: 50px;
    position: relative;
    .back-button {
      position: absolute;
      left: 20px;
      svg path {
        fill: lightGray;
      }
    }
    .pageTitle {
      font-family: $FontFamilyEveleth;
      color: #ffffff !important;
      font-size: 40px;
      margin-bottom: 20px;
    }
    .subtitle {
      text-transform: capitalize;
      font: normal normal normal 16px/18px Gotham;
      color: #ffffff !important;
      margin-bottom: 40px;
    }
    .search-box-result {
      width: 50%;
      margin: auto;
      .search-box {
        width: 100%;
        margin-bottom: 20px;
        .MuiOutlinedInput-root {
          background: #ffffff;
          border-radius: 6px;
          input::-moz-placeholder {
            font-family: Gotham !important;
          }
          svg {
            path {
              fill: gray;
            }
          }
          .search-icon {
            padding: 10px;
          }
          .close-icon {
            cursor: pointer;
          }
          fieldset {
            border: 0px solid gray !important;
            outline: 0px solid gray !important;
          }
        }
      }
      .search-result {
        width: 100%;
        text-align: left;
        padding-left: 0;
        li {
          font: normal normal bold 16px/18px Gotham;
          letter-spacing: 0px;
          opacity: 1;
          margin-bottom: 20px;
          color: #ffffff;
          a {
            color: #ffffff;
            &:hover {
              color: $primaryColor;
            }
          }
        }
      }
      .unable-to-find {
        text-align: left;
        font: normal normal normal 16px/18px Gotham;
        letter-spacing: 0px;
        color: #c2c6c7;
        opacity: 1;
        text-align: left;
        a {
          color: #c2c6c7;
          text-decoration: underline !important;
          &:hover {
            text-decoration: none !important;
          }
        }
      }
    }
  }
  .question-category {
    padding: 40px 10%;
    float: left;
    background: #f0f0f0 0% 0% no-repeat padding-box;
    .card-grid {
      .card {
        box-shadow: 0px 0px 3px 00px black;
        .MuiCardContent-root {
          padding: 0;
          .card-body {
            text-align: left;
            font: normal normal normal 14px/22px Gotham;
            letter-spacing: 0px;
            color: #2b3134;
            opacity: 1;
            padding: 20px;
            min-height: 140px;
          }
          .card-title {
            padding: 20px;
            font-size: 12px;
            font-family: Eveleth;
            color: #ffffff;
            background: #5d6a6d;
            position: relative;
            .next-button {
              right: 5px;
              top: 10px;
              position: absolute;
              svg path {
                fill: #ffffff;
              }
              transition: right 1s;
            }
            &:hover {
              .next-button {
                right: 00px;
                svg path {
                  fill: #00eeff;
                }
              }
              background: #525d5e !important;
            }
          }
        }
      }
    }
  }
  .popular-question {
    width: 100%;
    padding: 40px;
    text-align: center;
    float: left;
    h5 {
      width: 100%;
      font-family: Gotham-Bold;
      float: left;
      font-size: 16px;
      margin-bottom: 20px;
      text-transform: capitalize;
    }
    .slider {
      width: 100%;
      float: left;
      display: flex;
      align-items: center;
      .slider-button {
        width: 10%;
        float: left;
        cursor: pointer;
        opacity: 0.5;
      }
      .slider-main {
        float: left;
        width: 80%;
        white-space: nowrap;
        overflow-x: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          width: 0em !important;
          height: 0em !important;
          display: none !important;
          color: transparent;
          background: transparent;
        }
        // text-overflow: ellipsis;
        a {
          font-family: Gotham;
          color: black !important;
          text-decoration: underline !important;
          margin-left: 25px;
          &:hover {
            text-decoration: none !important;
            color: $primaryColor !important;
          }
        }
      }
    }
  }
  .scalton {
    float: left;
    width: 100%;
    .MuiSkeleton-root {
      width: 100%;
      float: left !important;
    }
    hr {
      margin: 0;
    }
  }
}

@media screen and (max-width: $medium) {
  #help-page {
    .contact-info {
      padding: 50px 2%;
      .contact-us {
        width: 100%;
      }
    }
  }
}
