@import "../layouts/layout1.scss";

.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px !important;
}

.progressBar {
  width: 14px !important;
  height: 14px !important;
  margin-left: 5px !important;
  /* display: none!important; */
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

// .MuiSvgIcon-root {
//     margin: 20px!important;
// }
.MuiButton-containedPrimary {
  background-color: #ffa865 !important;
}

.PageNo input,
.PageNo .MuiSelect-outlined.MuiSelect-outlined {
  background: white !important;
  padding: 7px 36px 7px 10px !important;
  margin: 0px 0px !important;
  color: $blackColor !important;
}

.PageNo {
  margin: 7px !important;
}

.MuiIconButton-colorPrimary {
  color: #3f51b5;
  border-radius: 20px !important;
}

.QuickFiltration .MuiFormControlLabel-label {
  font-size: 12px !important;
}

.QuickFiltration .MuiIconButton-root {
  padding: 0px !important;
}

.d-none {
  display: none !important;
}

.m-0 {
  margin: 0 !important;
}

.m-20 {
  margin: 20px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-5 {
  margin: 5px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.labelHeading {
  color: black !important;
  font-size: 14px !important;
  display: flex !important;
  justify-content: left !important;
  align-items: center !important;
}

.labelHeading svg {
  margin-right: 10px !important;
}

.text-gray {
  color: $brownColor !important;
  font-size: 14px !important;
}

.paperDialog {
  box-shadow: 0px 0px !important;
  float: left !important;
  width: 100% !important;
}

.Dialog .MuiPaper-elevation1 {
  box-shadow: 0px 0px !important;
}

.dialogText {
  color: $blackColor;
  display: block;
  font-weight: 100 !important;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  word-break: break-all;
}

.diaglogTextGray {
  color: $blackColor !important;
  opacity: 0.5 !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fs-24 {
  font-size: 16px !important;
}

.d-none {
  display: none !important;
}

.border-radius-50 {
  border-radius: 50px !important;
}

.float-right {
  float: right !important;
}

.carousel .thumbs-wrapper,
.carousel .control-dots {
  display: none !important;
}

.carousel .slide .legend {
  transition: all 0.5s ease-in-out !important;
  position: relative !important;
  bottom: 0 !important;
  left: 0% !important;
  margin-left: 0% !important;
  width: 100% !important;
  border-radius: 0 !important;
  background: #0000 !important;
  color: #000000 !important;
  padding: 10px !important;
  font-size: 12px !important;
  text-align: center !important;
  opacity: 1 !important;
}

.Float-Left {
  float: left !important;
}

.Float-Right {
  float: right !important;
}

.h-15 {
  height: 15px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.carousel.carousel-slider .control-arrow {
  background: rgb(218, 216, 216) !important;
  opacity: 0.5 !important;
  top: 40% !important;
  color: rgb(0, 0, 0) !important;
  font-size: 26px !important;
  margin-top: 0 !important;
  padding: 10px !important;
  border-radius: 30px !important;
  height: 52px !important;
  width: 52px !important;
  // display: flex !important;
  align-content: center !important;
  flex-wrap: nowrap !important;
  box-shadow: 0px 0px 0px black !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0px 0px !important;
  z-index: 99999 !important;
  position: absolute !important;
}

.carousel .slide img {
  vertical-align: top !important;
  border: 0 !important;
  max-height: 250px !important;
}

.carousel-root:hover .carousel.carousel-slider .control-arrow {
  opacity: 0.8 !important;
}

.carousel .carousel-status {
  text-shadow: 0px 0px 0px rgb(0, 0, 0) !important;
  color: #000000 !important;
  background: white !important;
  opacity: 0.8 !important;
  border-bottom-left-radius: 10px !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid rgb(0, 0, 0) !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid rgb(0, 0, 0) !important;
}

.carousel-root {
  box-shadow: 0px 2px 10px lightgrey !important;
  padding: 10px !important;
  border-radius: 7px !important;
}

.MapBox-Class {
  width: 100% !important;
  // height: 180px!important;
  margin-top: 10px !important;
}

.MapBox-Class .map-container {
  width: 100% !important;
}

.Order-Popup .MuiTypography-body1 {
  font-size: 12px !important;
  line-height: 1.4 !important;
}

.text-gray,
.MuiTypography-subtitle2,
.dialogText {
  font-size: 12px !important;
}

.dialogClass .MuiDialogTitle-root {
  flex: 0 0 auto !important;
  margin: 0 !important;
  background: white !important;
  width: fit-content !important;
  padding: 10px 30px 0px 30px !important;
  border-radius: 20px 20px 0px 0px !important;
  margin-bottom: -12px !important;
  z-index: 0;
}

.dialogClass .MuiDialogTitle-root h2 {
  // display: flex;
  align-content: center;
  align-items: center;
}

.dialogClass .MuiPaper-root {
  background-color: #fff0 !important;
}

.dialogClass .MuiDialogContent-root {
  border-radius: 0px 20px 20px 20px !important;
}

.dialogClass .MuiDialogContent-root,
.dialogClass .MuiDialogActions-root {
  background-color: white !important;
}

.dialogClass .MuiPaper-elevation24 {
  box-shadow: 0px 0 0 !important;
}

.dialogClass .status {
  font-size: 12px;
  padding: 8px !important;
  border-radius: 6px !important;
  margin-left: 0pc !important;
}

.popup-tab {
  box-shadow: 0px 0 0 !important;
  border-bottom: 2px solid $lightGray !important;
}

.text-left {
  text-align: left !important;
}

.Product-wight {
  position: absolute;
  left: 8%;
  top: 10%;
  background: $blackColor;
  color: white;
  padding: 10px 7px;
  font-size: 18px;
  border-radius: 27px;
  display: flex;
  box-shadow: 0px 0px 10px $blackColor;
  font-weight: 900;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Product-wight span {
  font-size: 10px;
  padding-bottom: 12px;
}

.OneProduct:hover .carousel.carousel-slider .control-arrow {
  opacity: 0 !important;
  display: none;
}

.text-lightgray {
  color: rgb(184, 182, 182) !important;
}

.copyContent {
  color: #ffffff !important;
  background: black !important;
  border-radius: 50px !important;
  float: left !important;
  line-height: normal !important;
  padding: 7px !important;
}

.copyContent .MuiSvgIcon-root {
  font-size: 0.875rem !important;
}

.CopyContentAlert .MuiPaper-root {
  background-color: #d1ffdfed !important;
}

.CopyContentAlert .MuiAlert-standardError {
  background-color: rgb(243, 187, 182) !important;
}

.editIcon {
  font-size: 1rem !important;
  margin-left: 5px !important;
}

.orderPopUp-edit .MuiInputBase-input {
  line-height: 1em !important;
  font-size: 12px !important;
}

.orderPopUp-edit .MuiInputBase-inputMultiline {
  line-height: 1.4em !important;
  max-height: 80px !important;
  overflow: auto !important;
}

.display-flex {
  display: flex !important;
}

.edit-progress {
  width: 14px !important;
  height: 14px !important;
  color: black !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.display-flow-root {
  display: flow-root !important;
}

.editIcon-Btn {
  width: fit-content !important;
  min-width: fit-content !important;
  padding: 3px 4px !important;
}

.Audit-log-grid .MuiGrid-spacing-xs-3 {
  width: 100% !important;
  margin: 0px !important;
}

.addressEdit .MuiInputBase-multiline {
  padding: 6px 0 2px !important;
}

.editAddressButon {
  padding: 2px 10px 2px 4px !important;
  margin-top: 3px !important;
  margin-left: 5px !important;
}

.Address-Action-Button {
  text-align: end;
  margin-top: 10px;
}

.order-note .textarea-note {
  border-radius: 10px;
}

.order-note .your-note .MuiTypography-body1 {
  font-size: 18px !important;
  line-height: 1.4 !important;
  font-weight: 900;
}

.order-note .MuiListItem-gutters {
  display: flex !important;
  align-items: center !important;
}

.avatar-class .MuiListItemAvatar-alignItemsFlexStart {
  margin-top: 0 !important;
}

.list-note .MuiTypography-body1 {
  border: 1px solid #d2d1cf !important;
  padding: 20px !important;
  border-radius: 10px !important;
  font-size: 1em !important;
  margin-bottom: 20px !important;
  background-color: #ffffff !important;
  text-align: justify;
}
.list-note .note-sender-image {
  opacity: 0.5;
}

.list-note .triangle-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid $lightGray;
  position: relative;
  margin-left: 10px;
  display: none !important;
}

.order-note-heading {
  justify-content: space-between;
}

.Order-Popup .MuiGrid-spacing-xs-3 {
  width: 100% !important;
  margin: 0px !important;
  margin-top: -12px !important;
  margin-bottom: -12px !important;
}

.order-note-heading .addNote {
  background-color: #3f6fbc !important;
  color: white !important;
  padding: 10px 20px !important;
  border-radius: 20px !important;
  font-size: 12px !important;
}

.order-note-heading .addNote[disabled] {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.Order-Popup .popup-tab .MuiTab-textColorPrimary {
  color: #d7d6d5 !important;
  font-family: $FontFamilyEveleth;
}

.Order-Popup .popup-tab .Mui-selected {
  color: #3a3630 !important;
}

.delivary-btn-div {
  width: 100% !important;
  float: left !important;
  margin-bottom: 10px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivary-btn-div img {
  margin-right: 10px !important;
  opacity: 0.5;
}

.address {
  margin-bottom: 3px !important;
}

.exportCSV {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}

.refreshBTN {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.edit-Btn-popup {
  width: 100% !important;
}

.CarouselImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-size: auto 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

#mapBox-id {
  height: 250px !important;
  padding: 0px 20px 0px 15px;
  float: left;
}

.order-action-list {
  background: white !important;
}

.order-details-popup {
  .MuiBackdrop-root {
    background: transparent linear-gradient(180deg, #3a3630e5 0%, #000000e5 98%)
      0% 0% no-repeat padding-box;
  }
  .MuiDialog-paperScrollPaper {
    height: calc(100% - 64px);
    max-height: 700px !important;
    border-radius: 20px 20px 20px 20px;
    .btn-close {
      padding: 5px;
      position: absolute;
      opacity: 0.5;
      right: 0;
      margin: 10px;
      svg {
        height: 40px;
        width: 40px;
      }
      &:hover {
        opacity: 1;
      }
    }
    #scroll-dialog-title {
      background: #f0f0f0 0% 0% no-repeat padding-box;
      .left-side {
        float: left;
        width: 50%;
        display: inline-flex;
        align-items: center;
        svg {
          path {
            fill: #3a3630;
          }
          float: left;
          height: 20px;
          width: 20px;
        }
        span {
          float: left;
          margin-left: 7px;
          &.number {
            margin-right: 7px;
            font-size: 20px;
            font-family: Eveleth;
            letter-spacing: 0px;
            color: #3a3630;
          }
          &.order-status {
            font-size: 12px;
            font-family: Gotham !important;
            font-weight: 500;
            background: #3a3630;
            color: #ffffff;
            padding: 10px 10px;
            border-radius: 10px;
            line-height: 12px;
          }
          &.updated {
            font-size: 13px;
            font-family: Gotham !important;
            font-weight: 500;
            border-radius: 10px;
            line-height: 13px;
            display: flex;
            align-items: center;
            float: left;
            .text {
              font-size: 12px;
              opacity: 0.5;
            }
            .date {
              margin-left: 0;
            }
          }
        }
      }
      .right-side {
        width: 50%;
        float: left;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;

        .next-previous-order {
          margin-right: 60px !important;
          font-family: Gotham !important;
          font-size: 14px;
          display: inline-flex;
          width: 350px;
          justify-content: center;
          span {
            line-height: 15px;
            position: relative;
            cursor: pointer;
            svg {
              height: 12px;
              width: 12px;
              position: absolute;
              opacity: 0.5;
            }
            &.next {
              svg {
                transition: right 1s;
                top: 10px;
                right: -20px;
              }
              padding: 10px;
              text-decoration: underline;
              transition: text-decoration 1s;
              &:hover {
                text-decoration: none;
                svg {
                  right: -30px;
                }
              }
            }
            &.previous {
              padding: 10px;
              text-decoration: underline;
              transition: text-decoration 1s;
              &:hover {
                text-decoration: none;
                svg {
                  left: -30px;
                }
              }
              svg {
                transition: left 1s;
                left: -20px;
              }
            }
            &.center {
              width: 1px;
              height: 30px;
              margin: 0px 10px;
              background: #3a3630 0% 0% no-repeat padding-box;
              opacity: 0.45;
            }
          }
        }
      }
    }
    .popup-content {
      padding: 0px;
      .MuiTabs-root {
        padding: 0px 20px;
      }
      .popup-tab {
        position: relative;
        .MuiTab-wrapper {
          font: normal normal 950 14px/19px Eveleth;
        }
        .edit-information {
          position: absolute;
          top: 0;
          padding: 14px;
          right: 20px;
          text-align: left;
          font: normal normal normal 14px/22px Gotham !important;
          color: #3a3630;
          opacity: 0.45;
          span {
            font-family: Gotham !important;
            letter-spacing: 0px;
            margin: 0px 10px;
          }
        }
      }
      .progress-bar-class {
        float: left;
        width: 100%;
        // text-align: center;
        // display: flex!important;
        // justify-content: center;
      }
      .overview {
        .editable {
          display: flex;
          justify-content: space-between;
          span {
            cursor: pointer;
            svg {
              height: 12px;
              width: 12px;
              &:hover {
                path {
                  fill: $blackColor !important;
                }
              }
            }
          }
        }
        .services {
          float: left;
          margin-top: 20px;
          .labelHeading {
            text-align: left;
            font: normal normal bold 16px/18px Gotham-Bold !important;
            letter-spacing: 0px;
            color: #3a3630;
            opacity: 1;
            text-transform: capitalize;
          }
          table {
            margin-top: 20px;
            tr {
              td {
                text-align: left;
                font: normal normal normal 14px/24px Gotham;
                letter-spacing: 0px;
                color: #3a3630;
                opacity: 1;
                &:nth-child(1) {
                  opacity: 0.65;
                  padding-right: 20px;
                }
                .services-bool {
                  height: 10px !important;
                  width: 10px !important;
                  &.false path {
                    fill: #e95252;
                    opacity: 1;
                  }
                  &.true path {
                    fill: #57db7a;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
        .customer-name {
          text-align: left;
          font: normal normal bold 14px Gotham;
          letter-spacing: 0px;
          color: #3a3630;
          opacity: 1;
        }
      }
    }
    .barcode {
      font-family: Gotham !important;
      margin-right: 0px !important;
    }
    .carousel-root {
      box-shadow: 0px 0px 0px lightgrey !important;
      padding: 0px !important;
      padding-bottom: 10px !important;
      border-radius: 0px !important;
      .carousel.carousel-slider {
        box-shadow: 0px 0px 5px lightgrey !important;
      }
      .product-description {
        text-align: left;
        font: normal normal normal 14px/27px Gotham;
        letter-spacing: 0px;
        color: #3a3630 !important;
        opacity: 1;
        margin: 20px 0px;
      }

      .product-id-and-barcode {
        display: flex !important;
        padding: 10px 0px 00px 0px !important;
        margin-bottom: 0px;
        .product-title {
          text-align: left;
          font: normal normal normal 14px/27px Gotham-Bold;
          line-height: 1;
          .product-del-active {
            width: 10px;
            height: 10px;
            background: green;
            border-radius: 10px;
            float: left;
            margin-right: 5px;
            &.deleted {
              background: red;
            }
          }
        }
        .product-code {
          text-align: left;
          font: normal normal normal 14px/27px Gotham;
          opacity: 0.6;
        }
      }
      .delivery-and-barcode {
        margin-bottom: 20px !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        .delivery-button {
          background: #888075 0% 0% no-repeat padding-box;
          border-radius: 6px;
          span {
            text-align: left;
            font: normal normal normal 12px/15px Gotham;
            letter-spacing: 0px;
            color: #ffffff;
            padding: 7px 10px;
            opacity: 1;
            text-transform: capitalize;
          }
        }
        .barcode {
          display: flex !important;
          justify-content: space-between;
          align-items: center;
          svg {
            padding-right: 10px;
            height: 18px;
            width: 30px;
            opacity: 0.7;
          }
        }
        span.barcode {
          text-align: left;
          font: normal normal normal 14px/15px Gotham;
          letter-spacing: 0px;
          color: #888075;
          opacity: 1;
        }
      }
    }
    .control-disabled {
      display: block !important;
    }
    button.control-arrow.control-next {
      display: none !important;
    }
    p.carousel-status {
      display: none !important;
    }
    button.control-arrow.control-prev {
      display: none !important;
    }
    .MuiButton-root {
      border-radius: 5px !important;
    }
    .count-and-pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      span.product-count {
        font: normal normal bold 16px/18px Gotham-Bold;
        letter-spacing: 0px;
        color: #3a3630;
        opacity: 1;
      }
      p.product-pagination {
        margin: 0px;
        display: inline-flex;
        align-items: center;
        span {
          text-align: right;
          font: normal normal normal 14px/16px Gotham;
          letter-spacing: 0px;
          color: #3a3630;
          opacity: 0.5;
          margin-right: 10px;
        }
        button {
          cursor: pointer;
          background: transparent;
          border: 0px solid;
          margin: 0px 0px;
          // &[disabled] {
          //     cursor: not-allowed;
          // }
          svg {
            height: 20px;
            width: 20px;
            path {
              fill: #3a3630;
            }
          }
          &:hover,
          &:active {
            svg {
              path {
                fill: $primaryColor;
              }
            }
          }
          // &[disabled]:hover {
          //     svg {
          //         path {
          //             fill: #3A3630;
          //         }
          //     }
          // }
        }
      }
    }
  }
  .audit-log-datagrid {
    padding: 0px 8px;
    .copyContent {
      border: 2px solid #707070;
      background: #ffffff !important;
      padding: 5px 8px !important;
      svg {
        height: 15px;
        width: 15px;
      }
      &:hover {
        background: #e4dddd !important;
      }
    }

    .MuiTableHead-root {
      .MuiTableCell-root {
        font-family: Gotham-Bold !important; //
        color: #3a36307a !important;
        text-transform: uppercase !important;
        padding: 8px;
        background-color: #ffffff;
      }
    }

    .MuiTableCell-root:last-child {
      opacity: 1 !important;
      .exportCSV {
        justify-content: flex-end;
      }
      button:nth-child(2) {
        svg {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
        background: #615e59 0% 0% no-repeat padding-box;
        border-radius: 66px !important;
        opacity: 1;
        color: #ffffff;
        padding: 10px 20px !important;
        line-height: 12px !important;
        font: normal normal normal 12px Gotham !important;
        box-shadow: 0px 0px 0px 0px !important;
        .MuiButton-label {
          text-transform: capitalize !important;
        }
        &:hover {
          box-shadow: 0px 0px 0px 0px !important;
        }
      }
    }
    tbody {
      tr td {
        padding: 16px 8px;
      }
    }
    .copy-cell {
      width: 100%;
      text-align: end;
      display: flex;
      justify-content: flex-end;
    }
    .line-height-12 {
      font: normal normal normal 12px Gotham !important;
    }
    .order-event-logged-by {
      padding: 10px 20px !important;
      background: #3a363010 0% 0% no-repeat padding-box !important;
      color: $blackColor !important;
      font: normal normal normal 14px/15px Gotham !important;
    }
  }
  .order-note {
    .no-note {
      margin: 15px 0px 30px 0px;
      p {
        text-align: left;
        font: normal normal normal 14px/24px Gotham;
        letter-spacing: 0px;
        color: #75726e;
        opacity: 1;
      }
    }
    .notes {
      text-align: left;
      font: normal normal 950 20px/28px Eveleth;
      letter-spacing: 0px;
      color: #3a3630;
      opacity: 0.7;
      svg {
        margin-right: 10px !important;
      }
    }
    .list-note {
      margin-bottom: 30px !important;
      div {
        img {
          width: 30px;
          border-radius: 30px;
        }
        .note-sender {
          margin-left: 010px;
          text-align: left;
          font: normal normal bold 14px/15px Gotham-Bold;
          letter-spacing: 0px;
          color: #3a3630;
          opacity: 1;
        }
        .note-created-at {
          margin-left: 010px;
          text-align: left;
          font: normal normal normal 14px/15px Gotham !important;
          letter-spacing: 0px;
          color: #3a3630;
          opacity: 0.5;
        }
        display: flex;
        align-items: center;
      }
      .note {
        margin-top: 10px;
        text-align: left;
        font: normal normal normal 14px/24px Gotham;
        letter-spacing: 0px;
        color: #3a3630;
        opacity: 1;
        background: #00000005 0% 0% no-repeat padding-box !important;
        border: 0px solid gray !important;
      }
    }
    .add-cancel-note {
      display: flex !important;
      justify-content: space-between;
      button {
        margin: 0px !important;
        background: #3f6fbc 0% 0% no-repeat padding-box;
        border-radius: 66px;
        .MuiButton-label {
          text-align: center;
          font: normal normal normal 12px Eveleth !important;
          letter-spacing: 0px !important;
          color: #ffffff !important;
          opacity: 1 !important;
          padding: 5px 15px;
        }
        border-radius: 50px !important;
        &.cancel {
          background: #70707000 0% 0% no-repeat padding-box;
          border: 2px solid #707070;
          opacity: 1;
          .MuiButton-label {
            color: #707070 !important;
            padding: 5px 20px;
          }
        }
      }
    }
  }
  .consignment-no {
    text-align: left !important;
    font: normal normal bold 18px/24px Gotham-Bold !important;
    letter-spacing: 0px !important;
    a {
      float: left;
      margin-bottom: 20px;
      span {
        margin-left: 20px;
      }
      text-decoration: underline !important;
      color: #3a3630 !important;
      &:hover {
        text-decoration: none !important;
        span {
          color: $primaryColor;
        }
      }
    }
    opacity: 1 !important;
  }
  .Product-wight {
    text-align: left;
    font-family: Eveleth;
    text-shadow: 0px 0px 0px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
  .editable-text-box {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    float: left;
    width: 100%;
    &.what3words {
      .edit {
        display: none;
      }
      .editable-box {
        .editable:not(.empty) {
          font-size: 16px;
          text-align: left;
          font-family: Gotham-Bold;
          letter-spacing: 0px;
          color: #e11f26;
          opacity: 1;
          justify-content: flex-start;
          &:before {
            content: "///";
            margin-right: 10px;
          }
          a {
            font-size: 16px;
            text-align: left;
            text-decoration: underline;
            font-family: Gotham-Bold;
            letter-spacing: 0px;
            color: #e11f26;
            opacity: 1;
            justify-content: flex-start;
          }
        }
      }
    }
    &.customer-name {
      span {
        font: normal normal bold 14px/15px Gotham-Bold !important;
        color: #3a3630;
        opacity: 1;
      }
      .edit {
        display: none;
      }
    }
    &.delivery-instructions {
      .text-box {
        .text {
          font-size: 14px;
        }
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }
      .editable-box {
        padding-top: 10px;
        // position: absolute;
        // top: 50px!important;
        .editable {
          min-height: 50px;
        }
      }
      .editable-popup {
        .MuiOutlinedInput-root.MuiInputBase-root {
          flex-direction: column;
          align-items: flex-end;
          .icons span {
            margin: 0px 0px 0px 12px;
          }
        }
      }
    }
    // &.delivery-instructions,
    // &.address {
    //     .editable {
    //         color: #3A3630;
    //     }
    // }
    .text-box,
    .editable-box {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      span {
        &.edit {
          cursor: pointer;
          &:hover {
            path {
              fill: black;
            }
          }
        }
        &.text {
          font: normal normal normal 12px/19px Gotham;
          letter-spacing: 0px;
          color: #3a3630;
          opacity: 0.65;
          white-space: nowrap;
        }
        &.editable {
          letter-spacing: 0px;
          width: 350px;
          font: normal normal normal 14px/24px Gotham;
          a {
            text-decoration: underline;
            font: normal normal normal 14px/24px Gotham;
            color: #3a3630;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    .editable-popup {
      // style={{ position: "relative", width: '350px', background: 'white', }}
      // position: relative;
      background: white;
      position: absolute;
      z-index: 999;
      padding: 15px;
      border-radius: 6px;
      border: 1px solid #d2d1cf !important;
      box-shadow: 0px 3px 10px #00000029;
      .body {
        background: #ffffff;
        div {
          .MuiOutlinedInput-root {
            background: #ffffff 0% 0% no-repeat padding-box;
            input {
              // padding: 15.5px 0px 20px 15.5px;
              padding: 0;
            }
            .MuiOutlinedInput-notchedOutline {
              // box-shadow: 0px 3px 10px #00000029;
              border: 0px solid #d2d1cf !important;
            }
          }
        }
        .text-fields-and-buttons {
          display: flex;
          align-items: flex-end;
          .text-field {
            padding: 10px;
            width: 350px;
            input {
              width: 100%;
              border-radius: 0;
              border: 0px solid;
              border-bottom: 1px dotted #d2d1cf !important;
            }
          }
        }
        .icons {
          display: flex;
          span {
            position: relative;
            padding: 10px !important;
            cursor: pointer;
            display: flex;
            border: 2px solid;
            margin: 12px 0px 12px 12px;
            align-items: center;
            justify-content: center;
            svg {
              position: absolute;
              height: 10px;
              width: 10px;
              path {
                opacity: 1;
              }
            }
            &.save {
              color: #57db7a;
              svg {
                path {
                  fill: #57db7a;
                }
              }
            }
            &.close {
              color: #e95252;
              svg {
                path {
                  fill: #e95252;
                }
              }
            }
          }
        }
      }
    }
  }
  .mapboxgl-ctrl-bottom-right {
    display: none !important;
  }
}

@media screen and (max-width: $medium) {
  .order-details-popup
    .MuiDialog-paperScrollPaper
    #scroll-dialog-title
    .right-side
    .next-previous-order {
    margin-right: 0px !important;
    margin-top: 10px !important;
  }
  .order-details-popup {
    .right-side,
    .left-side {
      width: 100% !important;
      .next-previous-order {
        margin-right: 0px !important;
      }
    }
    td,
    th {
      white-space: nowrap !important;
    }
    .consignment-no a span {
      margin-left: 0px !important;
      margin-top: 10px !important;
      float: left !important;
    }
    .center-div {
      border-bottom: 1px dashed lightGray;
      border-top: 1px dashed lightGray;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-8,
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-4 {
      width: 100% !important;
    }
    .editable-popup {
      max-width: 100%;

      transform: translate(-0%, -80%) !important;
    }
    #mapBox-id {
      padding: 0px !important;
    }

    .MuiDialog-paperScrollPaper {
      .popup-content {
        .popup-tab {
          .edit-information {
            display: none;
          }
        }
      }
      .carousel-root .delivery-and-barcode {
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        span.barcode {
          margin-top: 20px;
        }
      }
      #scroll-dialog-title {
        .left-side {
          span.updated {
            display: none;
          }
        }
      }
    }
  }
}
