#main-scroll {
  scroll-behavior: smooth;
}

.help {
  .contact-info {
    padding: 50px 20%;
    text-align: center;
    position: relative;
    float: left;
    width: 100%;
    h1 {
      font-family: Eveleth;
      font-size: 28px;
    }
    p {
      font-family: Gotham;
      font-size: 16px;
      padding-bottom: 40px;
      margin-top: 26px;
    }
    .contact-us {
      width: 40%;
      margin: auto;
      position: relative;
      display: flex;
      justify-content: center;
      img {
        width: 250px;
        height: 250px;
        border-radius: 1000px;
      }
      .contact-support {
        color: black;
        background: transparent
          linear-gradient(104deg, #55f5df 0%, #55d0f5 100%) 0% 0% no-repeat
          padding-box;
        border-radius: 66px;
        padding: 15px 30px;
        display: flex;
        font-family: Eveleth;
        text-align: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: -10px;
        font-size: 14px;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}
.store-refine-menu {
  width: 50%;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
  clear: both;
  display: block;
  position: relative;
  min-height: 36px;
  .common-menu {
    float: left;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff;
    background-color: darkcyan;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-family: 'Gotham';
  }
  .menu-store-refine {
    margin-left: 20px;
  }
}