@import "../layouts/layout4.scss";
@font-face {
  font-family: Eveleth;
  src: url("./../../assets/fonts/evelethclean-webfont.woff");
  // src: url('./../../assets/fonts/EvelethClean.otf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
$primaryColor: #f7c808;
$medium: 900px;
$FontFamilyEveleth: Eveleth;
#store-page {
  .store-center {
    min-height: 300px;
    background-image: url("../../assets/img/shopifyHelpCentre_featureBackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 12%;
    position: relative;
    .back-button {
      position: absolute;
      left: 20px;
      top: 16px;
      svg path {
        fill: lightGray;
      }
    }
    .search-box-result {
      width: 30%;
      margin: auto;
      .search-box {
        width: 100%;
        margin-bottom: 20px;
        .MuiInputBase-input {
          background: #ffffff;
          border-radius: 6px;
          input::-moz-placeholder {
            font-family: Gotham !important;
          }
          padding: 14px;
        }
      }
      .storeButtonsubmit {
        font-size: 0.9375rem !important;
      }
      .search-result {
        width: 100%;
        text-align: left;
        padding-left: 0;
        li {
          font: normal normal bold 16px/18px Gotham;
          letter-spacing: 0px;
          opacity: 1;
          margin-bottom: 20px;
          color: #ffffff;
          a {
            color: #ffffff;
            &:hover {
              color: $primaryColor;
            }
          }
        }
      }
      .unable-to-find {
        text-align: left;
        font: normal normal normal 16px/18px Gotham;
        letter-spacing: 0px;
        color: #c2c6c7;
        opacity: 1;
        text-align: left;
        a {
          color: #c2c6c7;
          text-decoration: underline !important;
          &:hover {
            text-decoration: none !important;
          }
        }
      }
    }
  }
  .pageTitle {
    font-family: Eveleth;
    color: #fff!important;
    font-size: 40px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: $medium) {
  #store-page {
    .contact-info {
      padding: 50px 2%;
      .contact-us {
        width: 100%;
      }
    }
  }
}
