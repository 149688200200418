@import "../layouts/layout1.scss";
#setting-page {
  margin: 0px !important;
  width: 90% !important;
  float: left !important;
  .pageTitle {
    font-family: $FontFamilyEveleth;
    color: #3a3630 !important;
  }
  .get-api-keys {
    display: flex;
    justify-content: flex-end;
    .main {
      display: flex;
      align-items: center;
      background: #f0f0f0;
      float: left;
      padding: 20px 40px;
      border-radius: 100px;
      span {
        text-align: right;
        line-height: 1 !important;
        margin: 0px !important;
        font: normal normal normal 12px Gotham;
        letter-spacing: 0px;
        color: #3a3630;
      }
      a {
        margin-left: 10px;
        text-align: right;
        font: normal normal normal 12px Eveleth;
        letter-spacing: 0px;
        color: #ffffff;
        background: #3a3630 0% 0% no-repeat padding-box;
        border-radius: 66px;
        line-height: 1 !important;
        // padding: 15px 20px 11px 18px;
        padding: 15px 18px;
        line-height: 1.5;
        &:hover {
          background: #feba30;
          color: #000000;
        }
      }
    }
  }
  .api-key-and-number {
    margin-top: 10px;
    display: flex;
    div {
      &.text-box {
        width: 47%;
        float: left;
        .title {
          text-align: left;
          font: normal normal bold 12px/13px Gotham;
          letter-spacing: 0px;
          color: #888075;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
        .input-box {
          background: #ffffff 0% 0% no-repeat padding-box;
          // border: 0px solid #D2D1CF;
          border-radius: 6px;
          letter-spacing: 0px;
          color: #3a3630;
          .MuiOutlinedInput-input {
            padding: 13.5px 14px;
            line-height: inherit !important;

            font: normal normal normal 14px/15px Gotham;
          }
        }
      }
      &.center-icon {
        width: 6%;
        display: inline-flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 10px;
      }
    }
  }
  .shopify-settings {
    h2 {
      text-align: left;
      font: normal normal normal 18px/25px Eveleth;
      letter-spacing: 0px;
      color: #3a3630;
      opacity: 1;
      border-bottom: 2px solid #3a363015;
      padding-bottom: 10px;
    }
    padding-bottom: 0px;
  }
  .dropdown {
    margin-top: 10px;
    margin-bottom: 10px;
    .title {
      text-align: left;
      font: normal normal bold 12px/13px Gotham;
      letter-spacing: 0px;
      color: #888075;
      text-transform: capitalize;
      margin-bottom: 15px;
      width: 100%;
      float: left;
    }
    .line {
      width: 100%;
      float: left;
      span {
        float: left;
        width: 30px;
        height: 2px;
        background: $primaryColor;
      }
    }
    .message {
      width: 100%;
      float: left;
      text-align: left;
      font: normal normal normal 14px/22px Gotham;
      letter-spacing: 0px;
      color: #7e7b77;
      opacity: 1;
      margin: 20px 0px;
      font-size: 0px;
      margin: 0px 0px;
    }
    .MuiOutlinedInput-inputMarginDense {
      padding-top: 15.5px !important;
      padding-bottom: 15.5px !important;
    }
    .input-box {
      background: #ffffff 0% 0% no-repeat padding-box;
      // border: 0px solid #D2D1CF;
      border-radius: 6px;
      letter-spacing: 0px;
      color: #3a3630;
      .MuiOutlinedInput-input {
        padding: 13.5px 14px;
        font: normal normal normal 14px/15px Gotham;
      }
    }
  }
  .switch-box-panel {
    background: #d8d8d8;
    border-radius: 10px;
    padding: 20px;
    &.active {
      background: transparent linear-gradient(0deg, #f0f0f0 0%, #fdfdfd 100%) 0%
        0% no-repeat padding-box;
      // svg path {
      //     fill: #FEBA30;
      // }
      h2 {
        color: #000000;
      }
      svg {
        &.batch {
          height: 25px;
          width: 25px;
          path.color {
            fill: #feba30 !important;
          }
          rect.color {
            fill: #feba30 !important;
          }
        }
        &:not(.batch) {
          path {
            fill: #feba30;
          }
        }
      }
    }
    .header {
      position: relative;
      display: flex;
      cursor: pointer;
      align-items: center;
      border-bottom: 1px solid #3a363015;
      padding-bottom: 10px;
      svg {
        height: 25px;
        width: 25px;
      }
      h2 {
        font: normal normal 950 14px/19px Eveleth;
        letter-spacing: 0px;
        color: #3a3630;
        opacity: 0.75;
        margin: 0px;
        margin-left: 20px;
      }
      .mui-switch {
        position: absolute !important;
        right: 0%;
        &.Switch-checked {
          background: $primaryColor;
        }
      }
    }
    .body {
      cursor: pointer;
      // margin-bottom: 20px;
      // padding-bottom: 20px;
      min-height: 90px;
      padding-top: 20px;
      text-align: left;
      font: normal normal normal 14px/22px Gotham;
      letter-spacing: 0px;
      color: #3a3630;
    }
    .batch-orders-auto-manual {
      h6 {
        font: normal normal normal 14px/22px Gotham;
        letter-spacing: 0px;
        color: #3a3630;
        opacity: 0.5;
        text-transform: initial;
        position: relative;
      }
      span {
        &.check-icon {
          width: 50px;
          position: absolute;
          height: 2px;
          background: #e7e6e6;
          left: 0;
          bottom: -4px;
        }
      }
      .batch-order-panel {
        border: 4px solid #e7e6e6;
        margin-top: 20px;
        border-radius: 10px;
        padding: 20px;
        display: inline-flex;
        align-items: flex-start;
        width: 100%;
        .check-box-icon {
          cursor: pointer;
          position: relative;
          span {
            position: relative;
            background: #88807550;
            height: 50px;
            width: 50px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              position: absolute;
              path {
                fill: #ffffff;
              }
            }
          }
        }
        .body {
          cursor: inherit;
          margin-left: 20px;
          padding: 0;
          h4 {
            margin-top: 0;
            font: normal normal 950 14px/19px Eveleth;
          }
          p {
            margin-bottom: 0;
            &.send-batch-order {
              margin-top: 10px;
              opacity: 0.7;
            }
          }
          .slot {
            cursor: pointer;
            width: 25%;
            float: left;
            border: 1px solid #b6b3b3;
            background: #e7e6e6;
            margin: 5px 8px;
            border-radius: 5px;
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            .radio-button {
              width: 15px;
              height: 15px;
              background: #ffffff;
              border-radius: 50px;
              border: 2px solid #00000090;
              align-content: center;
              align-items: center;
              justify-content: center;
              display: inline-flex;
              span {
                width: 8px;
                height: 8px;
                background: $primaryColor;
                border-radius: 50px;
              }
            }
            &:hover {
              border: 1px solid #000000;
              .radio-button {
                border: 1px solid #000000;
              }
            }
          }
        }
        &.active {
          border: 4px solid $primaryColor;
          .check-box-icon {
            span {
              background: $primaryColor;
            }
          }
        }
      }
    }
  }
  .submit-button {
    text-align: right;
    button {
      span {
        font: normal normal normal 12px Eveleth;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        line-height: 1.5;
        padding: 5px 10px 5px 10px !important;
      }
      background: #3a3630 0% 0% no-repeat padding-box;
      border-radius: 66px !important;
      box-shadow: 0px 0px 0px 0px red !important;
      &:hover {
        background: $primaryColor 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 0px 0px red !important;
        span {
          color: $blackColor;
        }
      }
    }
  }
}
@media screen and (max-width: $medium) {
  #setting-page {
    width: 100% !important;
    .get-api-keys {
      .main {
        padding: 10px 20px;
      }
    }
    .api-key-and-number {
      display: block;
      margin-top: 0px;
      padding-top: 0;
      padding-bottom: 0;
      div.center-icon {
        display: none;
      }
      div.text-box {
        margin-top: 30px;
        width: 100%;
      }
    }
    .switch-box-panel {
      .header {
        h2 {
          margin-right: 50px;
        }
      }
      .batch-orders-auto-manual {
        .batch-order-panel {
          .body {
            .slot {
              width: 40%;
            }
          }
        }
      }
    }
  }
}
// @media screen and (min-width: $medium) {
//   width: 100% !important;
//   //do Smth
// }

// .MuiFormControlLabel-labelPlacementStart {
//   margin-left: 0px !important;
// }
// .settingTitle {
//   text-align: left !important;
//   letter-spacing: 0px !important;
//   color: #3a363087 !important;
//   font-weight: 800 !important;
//   margin-bottom: 10px !important;
//   margin-top: 0px !important;
// }
// .progressBar {
//   width: 14px !important;
//   height: 14px !important;
//   margin-left: 5px !important;
//   color: white !important;
// }
// .align-right {
//   text-align: right !important;
// }
// .display-flex {
//   display: flex !important;
//   align-items: center;
// }
// .text-justify {
//   text-align: justify !important;
// }
// .font-size-14 {
//   font-size: 14px !important;
//   margin-left: 10px !important;
// }
// .d-none {
//   display: none !important;
// }
// .p-10-14 input {
//   padding: 10px 14px !important;
// }
// .p-10-14.MuiOutlinedInput-adornedEnd {
//   padding-right: 0px !important;
//   overflow: hidden !important;
// }
// .radioBtnLabel {
//   margin-right: 10px !important;
// }
// .App-Settings div[role="radiogroup"] .MuiFormControlLabel-root {
//   margin-right: 20px !important;
// }
// .App-Settings div[role="radiogroup"] .MuiRadio-root {
//   color: #f7c808 !important;
// }
// .vartcal-line {
//   // margin-left:10px;
//   padding-left: 10px;
//   border-left: 2px solid #dbd9d5;
// }
