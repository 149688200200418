@import "../layouts/layout1.scss";
#dispatch-page {
  margin: 0px !important;
  width: 100% !important;
  float: left !important;
  .pageTitle {
    font-family: $FontFamilyEveleth;
    color: #3a3630 !important;
    margin-bottom: 0px !important;
  }
  .search-box-grid {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }
  .count-pagination-searched-result {
    padding-top: 5px;
    justify-content: space-between;

    padding-bottom: 5px;
    margin-top: 10px;
    color: #615e59 !important;
    .pagination {
      display: flex;
      justify-content: flex-end;
      font-family: "Gotham";
    }
    .record-count {
      font-family: $FontFamilyGotham;
      font-size: 12px !important;
      float: left !important;
      margin-right: 10px;
    }
    .btn-clear {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
      cursor: pointer;
      text-decoration: underline !important;
      opacity: 0.5;
      &:hover {
        text-decoration: none !important;
      }
      .MuiButton-label {
        text-transform: capitalize !important;
      }
    }
    .btn-search-value {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
    }
  }
  .product-table {
    thead {
      tr {
        th {
          font-family: Gotham-Bold !important;
          font-size: 12px !important;
          padding: 9px 12px !important;
          white-space: nowrap !important;
          background: #615e59 0% 0% no-repeat padding-box;
          color: white !important;
          &:nth-last-child(1) {
            border-radius: 0px 6px 0px 0px !important;
          }
          &:nth-child(1) {
            border-radius: 6px 0px 0px 0px !important;
          }
          .created-icon {
            margin-top: 3px;
            margin-left: 5px !important;
            // height: 28px;
            // width: 28px;
            // position: absolute;
            // top: 10px;
          }
          &:last-child {
            text-align: end;
            button {
              font-family: Gotham;
              font-size: 14px !important;
              color: #ffffff;
              text-transform: capitalize;
              .MuiButton-label span {
                opacity: 0.5;
                text-decoration: underline;
              }
              svg {
                margin-left: 10px;
              }
              &:hover {
                .MuiButton-label span {
                  opacity: 1 !important;
                }
              }
            }
          }
        }
      }
    }
    tbody {
      border: 1px solid gray !important;
      tr {
        &:hover {
          background: #615e5910 0% 0% no-repeat padding-box;
        }
        // &.checked {
        //     background: #615e5910 0% 0% no-repeat padding-box;
        // }
        td {
          font-family: Gotham !important;
          font-size: 12px !important;
          padding: 12px 12px !important;
          &:nth-child(1) {
            // border-right: 1px solid rgba(224, 224, 224, 1);
            border-left: 1px solid rgba(224, 224, 224, 1);
            img {
              width: 100px;
              margin-right: 10px;
            }
            font-family: Gotham-Bold !important;
            width: 50%;
          }
          &:last-child {
            border-right: 1px solid rgba(224, 224, 224, 1);
            text-align: right;
            button {
              margin-left: 50px;
            }
          }
          .dispatch-orders {
            thead {
              tr {
                th {
                  font-family: Gotham-Bold !important;
                  font-size: 12px !important;
                  padding: 15px 12px !important;
                  white-space: nowrap !important;
                  background: #e6e5e4 0% 0% no-repeat padding-box;
                  text-align: left;
                  color: #615e59 !important;
                  &:nth-last-child(1) {
                    border-radius: 0px 6px 0px 0px !important;
                  }
                  &:nth-child(1) {
                    border-radius: 6px 0px 0px 0px !important;
                  }
                  &.MuiTableCell-stickyHeader {
                    z-index: 1;
                  }
                }
              }
            }
            tbody {
              border: 1px solid gray !important;
              tr {
                // cursor: pointer;
                background: #ffffff 0% 0% no-repeat padding-box;
                &:hover {
                  background: #615e5910 0% 0% no-repeat padding-box;
                }
                td {
                  font-family: Gotham !important;
                  font-size: 12px !important;
                  padding: 12px 12px !important;
                  &:nth-child(1) {
                    // border-right: 1px solid rgba(224, 224, 224, 1);
                    width: inherit;
                  }
                  &:last-child {
                    text-align: right;
                    button {
                      margin-left: inherit;
                    }
                  }
                  &.address {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 30ch;
                  }
                  &.order-action {
                    // padding: 0px 12px!important;
                    div {
                      display: flex;
                      justify-content: space-between;
                      // justify-content: flex-end;
                      .more-info {
                        margin-right: 10px;
                        white-space: nowrap;
                        span {
                          font: normal normal normal 10px/14px Eveleth !important;
                          line-height: 1 !important;
                        }
                        letter-spacing: 0px;
                        color: #888075;
                        opacity: 1;
                        padding: 12px 20px;
                        background: #f7f7f7 0% 0% no-repeat padding-box;
                        border: 1px solid #ebebeb;
                        border-radius: 66px !important;
                        opacity: 1;
                        &:hover {
                          background: $primaryColor;
                          color: $blackColor;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .count-pagination-searched-result {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    color: #615e59 !important;
    .pagination {
      display: flex;
      justify-content: flex-end;
      font-family: "Gotham";
    }
    .record-count {
      font-family: $FontFamilyGotham;
      font-size: 12px !important;
      float: left !important;
      margin-right: 10px;
    }
    .btn-clear {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
      cursor: pointer;
      text-decoration: underline !important;
      opacity: 0.5;
      &:hover {
        text-decoration: none !important;
      }
      .MuiButton-label {
        text-transform: capitalize !important;
      }
    }
    .btn-search-value {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
    }
  }
}

@media screen and (max-width: $medium) {
  #dispatch-page {
    .search-box-grid {
      display: none !important;
    }
  }
}
