.ErrorClass {
  font-size: 6.5rem !important;
}

.Error-layout .MuiTypography-h1 {
  margin: 0% !important;
}

.Error-layout {
  text-align: center !important;
}
