@import "../layouts/layout1.scss";
#help-category {
  .pageTitle {
    font-family: Gotham-Bold !important;
  }
  .help-center {
    background: #5d6a6d;
    text-align: center;
    padding: 30px 10px;
    position: relative;
    display: flex;
    align-items: center;
    // justify-content: space-around;
    .back-button {
      position: absolute;
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
    h1 {
      margin: 0;
      margin-left: 10%;
      line-height: 2;
      width: 80%;
      text-align: left;
      color: #ffffff;
      font-family: Eveleth;
      font-size: 18px;
    }
    .mail-icon {
      background: transparent linear-gradient(137deg, #55f5df 0%, #55d0f5 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 20px #0000001a;
      color: #000000;
      padding: 10px;
      border-radius: 50px;
      display: flex;
      position: absolute;
      right: 30px;
    }
  }
  .breadcrumb {
    padding: 25px 10px;
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #0000000d;
    a,
    span {
      text-decoration: underline;
      font: normal normal normal 14px/15px Gotham;
      letter-spacing: 0px;
      color: #7e7c78;
      &:last-child {
        opacity: 0.65;
      }
      &:first-child {
        margin-left: 10%;
      }
    }
    span {
      text-decoration: none;
    }
  }
  .question-list {
    .main-div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .copy-link {
      margin-top: 30px;
      margin-bottom: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: 1px solid lightgray;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        font: normal normal normal 14px/15px Gotham;
        letter-spacing: 0px;
        color: #7e7c78;
        svg {
          height: 25px;
          width: 25px;
          path {
            opacity: 0.7;
          }
        }
        &.copy-text {
          cursor: pointer;
        }
        &.copied-text {
          color: green;
          font-size: 12px;
        }
      }
    }
    margin: 30px 10%;
    h6 {
      text-align: left;
      font: normal normal normal 14px/15px Gotham;
      letter-spacing: 0px;
      color: #7e7c78;
      opacity: 0.65;
      text-transform: lowercase;
      padding: 0px 0px 20px 0px;
      border-bottom: 1px solid #7e7c7860;
    }
    p {
      text-align: left;
      font: normal normal normal 14px/24px Gotham;
      letter-spacing: 0px;
      color: #3a3630;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        margin: 5px 0px;
        a {
          text-align: left;
          text-decoration: underline;
          font: normal normal normal 14px/24px Gotham;
          letter-spacing: 0px;
          color: #3a3630;
          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
    .category-list {
      ul {
        padding-left: 5px;
        list-style: none;
        .hide-show-icon {
          cursor: pointer;
          &:hover {
            path {
              fill: $primaryColor;
            }
          }
        }
        li {
          position: relative;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 15px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          .list {
            display: flex;
            justify-content: space-between;
          }
          a.active {
            text-decoration: none !important;
          }
          a:hover {
            color: $primaryColor;
          }
          &:before {
            position: absolute;
            top: 15px;
            left: 0;
            width: 10px;
            height: 1px;
            margin: auto;
            content: "";
            background-color: #7e7c7860;
          }
          &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 1px;
            height: 100%;
            content: "";
            background-color: #7e7c7860;
          }
          &:last-child:after {
            height: 15px;
          }
        }
        li {
          margin: 0px 0px;
          text-decoration: none !important;
          li {
            // text-decoration: none!important;
            a {
              font-family: Gotham;
              text-transform: capitalize;
            }
          }
          &.active {
            a {
              text-decoration: none !important;
            }
          }
          a {
            font-family: Gotham-Bold;
            text-transform: capitalize;
            color: #7e7c78;
          }
        }
        li {
          a {
            text-decoration: none !important;
          }
          li {
            a {
              text-decoration: underline !important;
            }
          }
        }
      }
    }
  }
  .next-category {
    background: #f0f0f0 0% 0% no-repeat padding-box;
    padding: 50px;
    text-align: center;
    h1 {
      margin: 0;
      font: normal normal 26px/28px Gotham-Bold;
      letter-spacing: 0px;
      color: #3a3630;
      opacity: 1;
      // border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
    a {
      text-align: center;
      text-decoration: underline;
      font: normal normal normal 14px/22px Gotham;
      letter-spacing: 0px;
      color: #7e7c78;
      opacity: 1;
    }
    hr {
      width: 300px;
      margin: 30px auto;
    }
  }
}
