@import "../layout1.scss";
.popup-component {
  background: transparent
    linear-gradient(180deg, $blackColor 0%, $blackColor 100%) 0% 0% no-repeat
    padding-box;
  opacity: 0.98;
  .MuiDialog-paperWidthSm {
    max-height: 600px;
  }
  h1 {
    text-align: center;
    font: normal normal normal 26px/36px Eveleth;
    letter-spacing: 0px;
    color: #3a3630;
    opacity: 1;
  }
  p {
    text-align: center;
    font: normal normal normal 16px/24px Gotham;
    letter-spacing: 0px;
    color: #3a3630;
    opacity: 1;
  }
  .MuiTypography-h6 {
    float: right !important;
  }
  .MuiPaper-rounded {
    border-radius: 20px !important;
  }
  .MuiDialogContent-root.MuiDialogContent-dividers {
    border-bottom: 0px !important;
    border-top: 0px !important;
    padding: 0 80px !important;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    font: normal normal normal 26px/36px Eveleth;
  }
  .MuiDialogActions-spacing {
    align-items: center;
    justify-content: center;
    margin-top: 30px !important;
    margin-bottom: 75px !important;
    button {
      background: #3a3630 0% 0% no-repeat padding-box;
      border-radius: 66px;
      .MuiButton-label {
        text-align: center;
        font: normal normal normal 12px Eveleth !important;
        letter-spacing: 0px !important;
        color: #ffffff !important;
        opacity: 1 !important;
        padding: 10px 30px;
      }
      border-radius: 50px !important;
    }
    .button-cancel {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid $blackColor;
      opacity: 0.5;
      .MuiButton-label {
        color: $blackColor !important;
        padding: 10px 20px;
      }
    }
  }
}
// div.red button span {
//     color: red;
// }
// div.blue button span {
//     color: rgb(30, 255, 0);
// }
