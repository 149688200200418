@import "../layouts/layout1.scss";
.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px !important;
}

.progressBar {
  width: 14px !important;
  height: 14px !important;
  margin-left: 5px !important;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-8 {
  margin-top: 8px !important;
}

.mb-8 {
  margin-bottom: 8px;
}

.MuiButton-containedPrimary {
  background-color: #ffa865 !important;
}

.PageNo input,
.PageNo .MuiSelect-outlined.MuiSelect-outlined {
  background: white !important;
  padding: 3px 30px 3px 5px !important;
  margin: 0px 0px !important;
  color: $blackColor !important;
}

.PageNo {
  margin: 7px 3px !important;
}

.MuiIconButton-colorPrimary {
  color: #3f51b5;
  border-radius: 20px !important;
}

.QuickFiltration-heading .MuiFormControlLabel-label,
.QuickFiltration .MuiFormControlLabel-label {
  font-size: 12px !important;
}

.QuickFiltration .MuiIconButton-root {
  padding: 0px !important;
}

.d-none {
  display: none !important;
}

.m-0 {
  margin: 0 !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.labelHeading {
  color: black !important;
  font-size: 14px !important;
}

.text-gray {
  color: $brownColor !important;
  font-size: 14px !important;
}

.QuickFiltration .MuiFormControlLabel-label {
  color: $brownColor !important;
  padding-right: 8px !important;
  padding-left: 3px !important;
}

.paperDialog {
  box-shadow: 0px 0px !important;
}

.Dialog .MuiPaper-elevation1 {
  box-shadow: 0px 0px !important;
}

.dialogText {
  color: $blackColor;
  font-weight: 100 !important;
}

.diaglogTextGray {
  color: $blackColor !important;
  opacity: 0.5 !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fs-24 {
  font-size: 16px !important;
}

.MuiDataGrid-columnHeaderWrapper {
  background-color: #3a3630 !important;
  opacity: 0.8 !important;
  color: white !important;
}

.MuiDataGrid-columnHeaderWrapper .MuiCheckbox-root {
  color: white !important;
}

.MuiDataGrid-root {
  font-size: 12px !important;
}

.datatable .MuiDataGrid-root,
.MuiDataGrid-root,
.quick-filter .MuiSelect-root,
.quick-filter {
  font-size: 12px !important;
}

.MuiButton-root {
  font-size: 10px !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  display: grid !important;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

// Checkbox
.quick-filter .MuiSvgIcon-root,
.datatable .MuiDataGrid-checkboxInput .MuiSvgIcon-root {
  width: 18px !important;
  height: 18px !important;
}

.CircularProgress .MuiTypography-caption {
  font-size: 15px !important;
  font-weight: 800 !important;
}

.MuiTab-labelIcon {
  color: #3a36307a !important;
}

.SerchInput {
  margin: 10px !important;
}

.SerchInput .MuiInputBase-input {
  border: 1px solid rgb(177, 171, 171) !important;
}

.tab-panel {
  border-bottom: 2px solid #3a363026;
}

.tab-panel .Mui-selected {
  color: $blackColor !important;
}

.MuiTabs-indicator {
  background-color: #f8c808 !important;
  color: $blackColor !important;
  border-bottom: 3px solid #f8c808 !important;
  display: flex !important;
  justify-content: center !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.MuiTypography-colorTextSecondary .result {
  color: 1px solid rgb(177, 171, 171) !important;
}

.tab-panel .MuiTabs-flexContainer {
  display: flex !important;
  justify-content: center !important;
  min-width: 650px;
  overflow: auto;
}

.MuiDataGrid-columnsContainer {
  border: 0px solid !important;
  border-radius: 6px 6px 0px 0px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  line-height: 50px !important;
}

.datatable {
  border-radius: 6px 6px 0px 0px !important;
}

.form-search {
  display: flex !important;
  align-items: center;
  margin-right: 10px !important;
}

.MuiTab-wrapper {
  text-transform: capitalize !important;
}

.bg-progressBar {
  position: absolute !important;
  color: $lightGray !important;
}

.front-progressBar {
  z-index: 1 !important;
  background: transparent !important;
}

.MuiTab-wrapper {
  font-size: 12px !important;
}

.makeStyles-root-59,
.makeStyles-root-71 {
  float: left !important;
  margin: 10px !important;
}

.z-index-999 {
  z-index: 999;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: solid #3fb55300 0px !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: solid rgba(63, 81, 181, 0.5) 0px !important;
  outline-width: 0px !important;
  outline-offset: -0px !important;
}

#scroll-dialog-close button {
  color: white !important;
}

#scroll-dialog-close {
  color: white;
  width: fit-content !important;
  position: absolute;
  right: 0;
  background: transparent !important;
  font-weight: 800;
  color: white;
  padding: 0px !important;
  cursor: pointer;
  margin-bottom: 0px !important;
}

.quick-filter .MuiGrid-grid-md-8 {
  margin-top: 6px;
}

.MuiDataGrid-cell div {
  display: flex;
  align-items: center;
}

.datatable div[data-field="Address"],
.datatable div[data-field="Exception"] {
  min-width: 260px;
  max-width: 260px;
  line-height: 51px;
  min-height: 52px;
  max-height: 52px;
  line-height: 1.2 !important;
  display: flex !important;
  align-items: center !important;
  overflow: inherit !important;
  word-break: break-word !important;
  white-space: inherit;
}

.datatable div[data-field="Exception"] {
  color: red;
}

.datatable div[data-field="Exception"] .MuiDataGrid-columnHeaderTitle {
  color: rgb(255, 255, 255);
}

.Order-page .MuiDataGrid-row .MuiDataGrid-cell:first-child {
  border-right: 1px solid rgb(224, 224, 224) !important;
}

.datatable .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 0px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.width-100 {
  width: 80px !important;
}

.inputDate input,
.quick-filter-md-9 .action-btn-div button,
.inputDate .MuiSelect-outlined.MuiSelect-outlined {
  background: white !important;
  padding: 4px 4px 4px 10px !important;
  margin: 0px 0px !important;
  color: #3a3630 !important;
  width: 90px;
  font-size: 12px;
}

.quickPanel {
  display: flex !important;
}

.quick-filter .MuiFormControlLabel-root {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.QuickFiltration .MuiFormControlLabel-label {
  padding-right: 3px !important;
}

.quick-filter {
  padding: 0px 8px !important;
}

.quick-filter-md-9 {
  display: flex !important;
  align-items: center !important;
}

.quick-filter-md-9 .MuiInputBase-root {
  font-size: 12px !important;
}

.quick-filter-md-9 input[type="date"]::-webkit-calendar-picker-indicator {
  width: 15px !important;
  padding: 0px !important;
  margin: 0px !important;
}

.quick-filter-md-9 .MuiFormControl-root {
  display: flex !important;
  flex-direction: column !important;
  vertical-align: top !important;
  align-items: center !important;
  justify-content: center !important;
}

.quick-filter-md-3 {
  display: flex;
  justify-content: flex-end;
}

.quick-filter-md-9 .action-btn-div {
  display: flex !important;
  margin: 0px 0px !important;
  align-items: center !important;
}

.quick-filter-md-9 .action-btn-div button {
  height: fit-content !important;
  padding: 4px !important;
  align-items: center !important;
  margin-left: 5px !important;
}

.quick-filter-md-9 .action-btn-div .MuiButton-label {
  width: 100% !important;
  padding: 0 5px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.quick-filter-md-3 .dropdown-arrow {
  background-color: white !important;
  height: 25px !important;
  cursor: pointer;
}

.max-width-120 {
  max-width: 120px !important;
}

.quick-filter-md-9 .action-btn-div button {
  width: auto !important;
}

.quick-filter-md-9 .action-btn-div button .MuiButton-label {
  text-transform: capitalize !important;
  font-size: 12px !important;
  height: 18px !important;
  font-weight: 400 !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0px !important;
}

.MuiButton-root {
  min-width: 37px !important;
}

.quick-filter .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 0px !important;
}

.quick-filter .MuiFormControl-root .MuiInput-underline {
  border: 0px solid gray !important;
  width: 100% !important;
  border: 1px solid gray !important;
  border-radius: 4px !important;
  padding: 5px 5px 5px !important;
  max-width: 100px !important;
  background: white !important;
  margin-left: 5px !important;
}

.quick-filter .MuiFormControl-root .Mui-focused {
  border: 1px solid gray !important;
  outline: 0px solid gray !important;
}

.quick-filter .MuiFormControl-root MuiInput-root MuiInput-underline:active,
.quick-filter .MuiFormControl-root MuiInput-root MuiInput-underline:focus,
.quick-filter
  .MuiFormControl-root
  MuiInput-root
  MuiInput-underline:focus-within,
.quick-filter .MuiFormControl-root MuiInput-root MuiInput-underline:visible,
.quick-filter
  .MuiFormControl-root
  MuiInput-root
  MuiInput-underline:focus-visible {
  border: 1px solid gray !important;
  outline: 0px solid gray !important;
}

.quick-filter .MuiFormControl-root .MuiInput-underline:before,
.quick-filter .MuiFormControl-root .MuiInput-underline:after {
  border-bottom: 0px !important;
}

@media only screen and (max-width: 600px) {
  .CircularProgressWithLabel-BTN {
    padding: 6px 6px !important;
  }
}

.Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.input-date {
  width: 100% !important;
  border: 1px solid gray !important;
  border-radius: 4px !important;
  padding: 5px 5px 5px !important;
  max-width: 120px !important;
  background: white !important;
  margin-left: 5px !important;
}

.react-datepicker-wrapper {
  width: auto !important;
  display: flex !important;
  flex-direction: column !important;
  vertical-align: top !important;
  align-items: center !important;
  justify-content: center !important;
}

.datePicker {
  border: 0px solid gray !important;
  width: 100% !important;
  border: 1px solid gray !important;
  border-radius: 4px !important;
  padding: 5px 5px 5px !important;
  max-width: 80px !important;
  background: white !important;
  margin-left: 5px !important;
}

.order-status-new {
  background-color: #93c47d !important;
}

.order-status-deleted {
  background-color: #9fc5e8 !important;
}

.order-status-order-created {
  background-color: #93c47d !important;
}

.order-status-marked-released {
  background-color: #a4c2f4 !important;
}

.order-status-order-released {
  background-color: #9fc5e8 !important;
}

.order-status-order-un-released {
  background-color: #9fc5e8 !important;
}

.order-status-product-received-into-warehouse {
  background-color: #9fc5e8 !important;
}

.order-status-all-delivery-products-received-at-depot {
  background-color: #9fc5e8 !important;
}

.order-status-all-products-not-received-at-depot {
  background-color: #9fc5e8 !important;
}

.order-status-scheduled-date-not-confirmed {
  background-color: #ffe599 !important;
}

.order-status-scheduled-date-declined {
  background-color: #ea9999 !important;
}

.order-status-scheduled-date-confirmed {
  background-color: #9fc5e8 !important;
}

.order-status-successfully-completed {
  background-color: #34a853 !important;
}

.order-status-order-cancelled {
  background-color: #cc0000 !important;
}

.order-status-order-uncancelled {
  background-color: #9fc5e8 !important;
}

.order-status-marked-cancelled {
  background-color: #9fc5e8 !important;
}

.order-status-scheduled-date-slot-confirmed {
  background-color: #f9cb9c !important;
}

.order-status-order-on-hold {
  background-color: #9fc5e8 !important;
}

.order-status-marked-created {
  background-color: #9fc5e8 !important;
}

.order-status-marked-deleted {
  background-color: #9fc5e8 !important;
}

.order-status-exception {
  background-color: #c7bb19 !important;
}

.react-datepicker-popper {
  z-index: 100;
}

@-webkit-keyframes rotating
/* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

#order-page {
  margin: 0px !important;
  width: 100% !important;
  float: left !important;
  .pageTitle {
    font-family: $FontFamilyEveleth;
    color: #3a3630 !important;
  }
  .search-box-grid {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }
  .tab-panel {
    .MuiTab-root {
      font-family: $FontFamilyGotham;
      &:hover {
        font-family: Gotham-Bold;
        span span span {
          font-weight: 500;
          font-family: Gotham;
        }
      }
      &.Mui-selected {
        font-family: Gotham-Bold;
        span span span {
          font-family: Gotham;
          // font-weight: 500;
        }
      }
      &.pending-tab .count,
      &.queued-tab .count,
      &.active-tab .count,
      &.completed-tab .count,
      &.exception-tab .count,
      &.cancelled-tab .count {
        padding: 3px 5px;
        border-radius: 14px !important;
        // opacity: 0.15;
      }
      &.pending-tab .count {
        background: #feb93025;
      }
      &.queued-tab .count {
        background: #55cff525;
      }
      &.active-tab .count {
        background: #6396e525;
      }
      &.completed-tab .count {
        background: #57db7a25;
      }
      &.exception-tab .count {
        background: #fe30f025;
      }
      &.cancelled-tab .count {
        background: #e9525225;
      }
    }
  }
  .count-pagination-searched-result {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    color: #615e59 !important;
    justify-content: space-between;
    .pagination {
      display: flex;
      justify-content: flex-end;
      font-family: "Gotham";
    }
    .record-count {
      font-family: $FontFamilyGotham;
      font-size: 12px !important;
      float: left !important;
      margin-right: 10px;
    }
    .btn-clear {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
      cursor: pointer;
      text-decoration: underline !important;
      opacity: 0.5;
      &:hover {
        text-decoration: none !important;
      }
      .MuiButton-label {
        text-transform: capitalize !important;
      }
    }
    .btn-search-value {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
    }
  }
  .quick-filter {
    background: #d7d6d530 0% 0% no-repeat padding-box !important;
    border-radius: 6px !important;
    // opacity: 0.2!important;
    padding: 6px 12px 6px 12px !important;
    margin: 6px !important;
    .quick-filter-md-9 {
      br {
        display: none;
      }
      margin-top: 0px !important;
      .MuiFormControlLabel-root {
        margin-left: 16px !important;
        &:nth-of-type(1) {
          margin-left: 0px !important;
          float: left !important;
        }
        .MuiFormControlLabel-label {
          font-family: Gotham !important;
          margin-left: 5px !important;
          &:nth-of-type(1) {
            color: #3a3630 !important;
          }
        }
      }
      .datePickers {
        display: flex;
      }
      .quick-filter-checkbox {
        margin-left: 16px !important;
        white-space: nowrap;
        label {
          margin-left: 5px !important;
          font-family: Gotham !important;
          font-size: 14px !important;
          cursor: pointer;
          color: #3a3630;
        }
      }
    }
    .quick-filter-md-3 {
      .MuiFormGroup-root.MuiFormGroup-row {
        display: flex !important;
        align-items: center !important;
        .per-page {
          // display: flex;
          // align-items: center;
          font-family: Gotham !important;
          font-size: 12px;
        }

        .PageNo {
          margin: 7px 14px !important;
          .MuiSelect-outlined.MuiSelect-outlined {
            padding: 5px 30px 5px 10px !important;
          }
          &.sorting-select {
            width: 200px !important;
            font-family: Gotham;
          }
        }
        @media screen and (max-width: $medium) {
          .per-page {
            font-size: 10px;
            margin-right: 5px !important;
          }

          .PageNo {
            margin: 5px 1px !important;
            // &:nth-child(2){
            //   margin-left: 5px;
            // }
            &.sorting-select {
              margin: 5px 2px !important;
            }
          }
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border: 1px solid gray !important;
          outline: 0px solid gray !important;
        }
      }
    }
  }
  .order-table {
    // max-width: 100%;
    // overflow: auto;
    thead {
      position: sticky;
      top: 0px;
      z-index: 99;
      padding: 50px;
      tr {
        font-size: 20px;
        th {
          font-family: Gotham-Bold !important;
          font-size: 12px !important;
          padding: 9px 12px !important;
          white-space: nowrap !important;
          background: #615e59 0% 0% no-repeat padding-box;
          color: white !important;
          &:nth-last-child(1) {
            border-radius: 0px 6px 0px 0px !important;
          }
          &:nth-child(1) {
            border-radius: 6px 0px 0px 0px !important;
          }
          .created-icon {
            // margin-top: 3px;
            margin-left: 5px !important;
          }
          &:last-child {
            text-align: end;
            button {
              font-family: Gotham;
              font-size: 14px !important;
              color: #ffffff;
              text-transform: capitalize;
              .MuiButton-label span {
                opacity: 0.5;
                text-decoration: underline;
              }
              svg {
                margin-right: 10px;
              }
              &:hover {
                .MuiButton-label span {
                  opacity: 1 !important;
                }
              }
            }
          }
        }
      }
    }
    tbody {
      border: 1px solid gray !important;
      tr {
        &.active,
        &:hover {
          background: #615e5910 0% 0% no-repeat padding-box;
        }
        // &.checked {
        //     background: #615e5910 0% 0% no-repeat padding-box;
        // }
        td {
          font-family: Gotham !important;
          font-size: 12px !important;
          padding: 12px 12px !important;
          &:nth-child(1) {
            border-left: 1px solid rgba(224, 224, 224, 1);
          }
          &.check-box-td {
            border-right: 1px solid rgba(224, 224, 224, 1);
          }
          &:last-child {
            border-right: 1px solid rgba(224, 224, 224, 1);
          }
          // &:nth-child(2),
          // &:nth-child(3),
          // &:nth-child(5),
          // &:nth-child(6),
          // &:nth-child(4) {
          // }
          white-space: nowrap;
          // .action-bar {
          // }
          &.link {
            a {
              color: #3a3630;
              text-decoration: underline;
            }
          }
          &.order-status {
            white-space: break-spaces;
          }
          &.order-action {
            // padding: 0px 12px!important;
            line-height: 1.5;
            div {
              display: flex;
              justify-content: flex-end;
              .more-info {
                margin-right: 10px;
                white-space: nowrap;
                span {
                  font: normal normal normal 10px/14px Eveleth !important;
                  line-height: 1 !important;
                }
                letter-spacing: 0px;
                color: #888075;
                opacity: 1;
                padding: 12px 20px;
                background: #f7f7f7 0% 0% no-repeat padding-box;
                border: 1px solid #ebebeb;
                border-radius: 66px !important;
                opacity: 1;
                &:hover {
                  background: $primaryColor;
                  color: $blackColor;
                }
              }
            }
          }
          &.address {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 30ch;
          }
        }
      }
    }
  }
  .multi-order-action {
    z-index: 999;
    display: flex;
    background: #807e7a;
    position: fixed;
    bottom: 0;
    margin-right: -8px;
    margin-left: -8px;
    align-items: center;
    padding: 0px 0px 10px 0 !important;
    border-top: 1px solid #f0f0f0;
    float: left;
    width: calc(100% - 180px);
    .record-count {
      font-size: 12px !important;
      color: #ffffff;
      span {
        color: $primaryColor;
      }
    }
    .btn-clear {
      font-size: 12px !important;
      color: #ffffff !important;
      opacity: 0.5 !important;
    }
    .order-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .order-action-text {
        text-align: right;
        font: normal normal normal 12px Gotham;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.75;
      }
      .order-action-button {
        background: #3a3630 0% 0% no-repeat padding-box;
        border-radius: 66px !important;
        opacity: 1;
        &[data-tip-disable="false"] {
          opacity: 0.6;
          cursor: not-allowed;
        }
        span {
          font: normal normal normal 12px Gotham;
          color: #ffffff;
        }
        padding: 10px 16px;
        margin-left: 8px;
        svg {
          margin-right: 10px;
          width: 12px;
          height: 12px;
        }
        &.delete {
          background: #e95252 0% 0% no-repeat padding-box;
        }
        &.cancel {
          background: #dc6988 0% 0% no-repeat padding-box;
        }
      }
    }
  }
}

.Order-action-bar {
  li {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    margin: 0px 15px;
  }
  svg {
    margin-right: 10px;
  }
  .delete {
    // background: #E95252 0% 0% no-repeat padding-box;
    color: #e95252 !important;
    svg path {
      fill: #e95252 !important;
    }
    &:hover {
      background: #e95252 0% 0% no-repeat padding-box;
      color: white !important;
      opacity: 0.8;
      svg path {
        fill: #ffffff !important;
      }
    }
  }
  .cancel {
    color: #dc6988 !important;
    svg path {
      fill: #dc6988 !important;
    }
    // background: #DC6988 0% 0% no-repeat padding-box;
    &:hover {
      color: white !important;
      background: #dc6988 0% 0% no-repeat padding-box;
      opacity: 0.8;
      svg path {
        fill: #ffffff !important;
      }
    }
  }
}

.disable-tooltip {
  font-size: 16px !important;
  font-family: Gotham;
  border-radius: 10px !important;
  background: #e95252 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;

  &::after {
    display: none;
    border-top-color: #e95252 !important;
  }
}
.delete-orders-action {
  background: #e95252 0% 0% no-repeat padding-box !important;
}
.cancel-orders-action {
  background: #dc6988 0% 0% no-repeat padding-box !important;
}

.MuiSpeedDialAction-staticTooltipLabel {
  white-space: nowrap !important;
}
.mobile-orders-actions {
  display: none !important;
  span {
    &.MuiSpeedDialAction-staticTooltipLabel {
      font-family: Gotham !important;
      text-transform: uppercase;
      background: $blackColor !important;
      color: #ffffff;
    }
  }
  button.MuiButtonBase-root {
    background: $blackColor;
  }
  button:first-child {
    background: $primaryColor !important;
    color: $blackColor !important;
  }
}
@media screen and (max-width: $medium) {
  .mobile-orders-actions {
    display: flex !important;
  }
  .order-action-desktop {
    display: none !important;
  }
  #order-page {
    .search-box-grid {
      display: none !important;
    }
    .multi-order-action {
      width: 100% !important;
      .order-action {
        .order-action-desktop {
          display: none;
        }
      }
    }
    .quick-filter {
      @media screen and (max-width: $medium) {
        .QuickFiltration-heading .MuiFormControlLabel-label,
        .quick-filter-checkbox {
          white-space: nowrap;
          float: left;
        }
      }
      .quick-filter-md-9 {
        display: block !important;
        width: 100%;
        float: left;
        div.datePickers {
          float: left;
          display: flex;
          width: 100%;
          justify-content: space-between;
        }
        .react-datepicker-wrapper {
          width: calc(50% - 5px) !important;
          float: left !important;

          .react-datepicker__input-container {
            float: left !important;
            width: 100% !important;
            input {
              float: left !important;
              width: 100% !important;
              max-width: 100% !important;
              margin: 0 !important;
            }
            width: 100% !important;
          }
        }
      }
      .quick-filter-md-3 {
        svg {
          display: none;
        }
        .MuiFormGroup-root {
          .MuiFormGroup-row {
            .PageNo {
              margin: 7px 0px !important;
            }
          }
        }
      }
    }
  }
}
