@import "../layouts/layout1.scss";
#delivery-schedule-page {
  margin: 0px !important;
  width: 100% !important;
  float: left !important;
  .pageTitle {
    font-family: $FontFamilyEveleth;
    color: #3a3630 !important;
  }
  .search-box-grid {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }
  .buttons-add-export-import {
    display: flex;
    align-items: center;
    .separator {
      width: 1px;
      background: #ddd0bd70;
      height: 100%;
      margin: 0px 10px;
    }
    button {
      background: #3a3630 0% 0% no-repeat padding-box;
      border-radius: 66px !important;
      margin: 0px 5px !important;
      box-shadow: 0 0 0 !important;
      padding: 6px 20px;
      &.import-export {
        background: #615e59 0% 0% no-repeat padding-box;
      }
      &.export {
        .MuiButton-startIcon {
          top: -4px;
        }
      }
      &.import {
        .MuiButton-startIcon {
          bottom: -4px;
        }
      }
      &.add-product {
        .MuiButton-startIcon {
          top: -4px;
        }
      }
      span {
        font-family: Gotham;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: initial;
        font-size: 12px;
        position: relative;
        &.MuiButton-label {
          padding: 0px 0px 0px 20px;
          .MuiButton-startIcon {
            position: absolute;
            left: 0px;
          }
        }
      }
      svg {
        height: 22px !important;
      }
      &:hover {
        box-shadow: 0 0 0 !important;
        background: $primaryColor;
        span {
          color: $blackColor;
        }
        svg {
          path {
            fill: $blackColor;
            &.arrow {
              fill: #ffffff;
            }
          }
        }
        &.import-export {
          svg {
            path {
              fill: $primaryColor;
              &.arrow {
                fill: #6396e5;
              }
              &.main {
                fill: $blackColor;
              }
            }
          }
        }
      }
    }
  }
  .count-pagination-searched-result {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    color: #615e59 !important;
    justify-content: space-between;

    .pagination {
      display: flex;
      justify-content: flex-end;
      font-family: "Gotham";
    }
    .record-count {
      font-family: $FontFamilyGotham;
      font-size: 12px !important;
      float: left !important;
      margin-right: 10px;
    }
    .btn-clear {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
      cursor: pointer;
      text-decoration: underline !important;
      opacity: 0.5;
      &:hover {
        text-decoration: none !important;
      }
      .MuiButton-label {
        text-transform: capitalize !important;
      }
    }
    .btn-search-value {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
    }
  }
  .delivery-schedule-table {
    thead {
      tr {
        th {
          font-family: Gotham-Bold !important;
          font-size: 12px !important;
          padding: 9px 12px !important;
          white-space: nowrap !important;
          background: #615e59 0% 0% no-repeat padding-box;
          color: white !important;
          &:nth-last-child(1) {
            border-radius: 0px 6px 0px 0px !important;
          }
          &:nth-child(1) {
            border-radius: 6px 0px 0px 0px !important;
            width: 20%;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(3) {
            width: 15%;
          }
          &:nth-child(4) {
            width: 15%;
          }
          &.sorting-row {
            cursor: pointer;
            svg {
              position: absolute;
              right: 1pc;
              top: 12px;
              opacity: 0.5;
              path {
                fill: #ffffff;
              }
            }
            &:hover {
              svg {
                opacity: 1;
              }
            }
            &.active-sorting {
              svg {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    tbody {
      border: 1px solid gray !important;
      tr {
        &:hover {
          background: #615e5910 0% 0% no-repeat padding-box;
        }
        &.records {
          span {
            opacity: 0.5;
            margin-left: 10px;
            &:after {
              content: "]";
            }
            &:before {
              content: "[";
            }
          }
        }
        td {
          font-family: Gotham !important;
          font-size: 12px !important;
          padding: 12px 12px !important;
          white-space: nowrap;
          &:nth-child(1) {
            border-left: 1px solid rgba(224, 224, 224, 1);
          }
          &:last-child {
            border-right: 1px solid rgba(224, 224, 224, 1);
          }
          &.delivery-schedule {
            padding: 0px 12px !important;
            // display: flex;
            svg {
              height: 20px;
              width: 20px;
              &.close-icon path {
                fill: #e95252;
              }
              &.check-icon path {
                fill: #57db7a;
              }
            }
          }
        }
      }
    }
  }
  .count-pagination-searched-result {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    color: #615e59 !important;
    .pagination {
      display: flex;
      justify-content: flex-end;
      font-family: "Gotham";
    }
    .record-count {
      font-family: $FontFamilyGotham;
      font-size: 12px !important;
      float: left !important;
      margin-right: 10px;
    }
    .btn-clear {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
      cursor: pointer;
      text-decoration: underline !important;
      opacity: 0.5;
      &:hover {
        text-decoration: none !important;
      }
      .MuiButton-label {
        text-transform: capitalize !important;
      }
    }
    .btn-search-value {
      font-family: $FontFamilyGotham;
      float: left !important;
      min-width: fit-content !important;
      padding: 2px 5px !important;
      line-height: 1;
      font-size: 12px;
      color: #615e59 !important;
    }
  }
  .multi-order-action {
    z-index: 999;
    display: flex;
    background: #807e7a;
    position: fixed;
    bottom: 0;
    margin-right: -8px;
    margin-left: -8px;
    align-items: center;
    padding: 0px 0px 10px 0 !important;
    border-top: 1px solid #f0f0f0;
    float: left;
    width: calc(100% - 180px);
    .record-count {
      font-size: 12px !important;
      color: #ffffff;
      span {
        color: $primaryColor;
      }
    }
    .btn-clear {
      font-size: 12px !important;
      color: #ffffff !important;
      opacity: 0.5 !important;
    }
    .order-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .order-action-text {
        text-align: right;
        font: normal normal normal 12px Gotham;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.75;
      }
      .order-action-button {
        background: #3a3630 0% 0% no-repeat padding-box;
        border-radius: 66px !important;
        opacity: 1;
        span {
          font: normal normal normal 12px Gotham;
          color: #ffffff;
        }
        padding: 10px 16px;
        margin-left: 8px;
        svg {
          margin-right: 10px;
          width: 12px;
          height: 12px;
        }
        &.delete {
          background: #e95252 0% 0% no-repeat padding-box;
        }
        &.cancel {
          background: #dc6988 0% 0% no-repeat padding-box;
        }
      }
    }
  }
  .area-search {
    display: flex;
    align-items: center;
    margin-right: 40px;
    // .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator {
    //     display: none!important;
    // }
    h6 {
      margin-right: 10px;
      text-align: right;
      font: normal normal normal 14px/15px Gotham;
      letter-spacing: 0px;
      color: #3a3630;
      text-transform: capitalize;
      opacity: 0.6;
      white-space: nowrap;
    }
    div {
      &.MuiOutlinedInput-root.MuiInputBase-root {
        // width: 250px;
        // .MuiSelect-select.MuiSelect-outlined {
        padding: 3px 10px;
        // }
      }
    }
    // .list-ul {
    //     height: 300px!important;
    //     // width: calc(10% + 1px);
    // }
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    height: 400px;
    margin-top: 5px;
    .area-list {
      margin-left: 10px !important;
    }
    .area-search-option {
      margin-left: 10px;
    }
  }
}

.MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
  display: none !important;
}
@media screen and (max-width: $medium) {
  #delivery-schedule-page {
    .search-box-grid-div {
      display: none !important;
    }
    .area-search {
      margin-right: 00px;
      .MuiAutocomplete-root.MuiAutocomplete-fullWidth {
        width: 270px;
      }
    }
  }
}
