/*Variables*/

@font-face {
  font-family: Gotham;
  src: url(./../../assets/fonts/Gotham-Book.ttf);
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: Gotham-Bold;
  src: url(./../../assets/fonts/Gotham-Bold.ttf);
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: Eveleth;
  src: url("./../../assets/fonts/evelethclean-webfont.woff");
  // src: url('./../../assets/fonts/EvelethClean.otf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

$primaryColor: #f7c808;
$orange: #ffa865;
$brownColor: #888075;
$darkColor: #aca297;
$blackColor: #3a3630;
$lightGray: #f3f3f3;
$green: #55be71;
$red: #c43232;
$white: #ffffff;
$FontFamilyEveleth: Eveleth;
$FontFamilyGotham: Gotham;
$BJS_darkBrown: #3a3630;
$BJS_brown: #888075;
$BJS_yellow: #feba30;
$BJS_brown_40: #cfccbf;
$BJS_brown_30: #dbd9d5;
$BJS_brown_20: #e7e6e3;
$BJS_brown_10: #f0f0f0;
$BJS_blue: #3e6ebb;
$BJS_red: #c43232;
$BJS_green: #55be71;
$BJS_pink: #ff9a9a;
$BJS_orange: #ffa865;
$BJS_lightBlue: #b1c8ec;
$small: 300px;
$medium: 900px;
@mixin set-font($family: $FontFamilyGotham, $weight: 400, $style: normal) {
  font-family: $family;
  font-style: $style;
  font-weight: $weight;
}

.text-green {
  color: $green !important;
}

.text-black {
  color: $blackColor !important;
}

.text-brownColor {
  color: $brownColor !important;
}

.text-white {
  color: $white !important;
}

.bg-brownColor {
  background-color: $brownColor !important;
}

.text-red {
  color: $red !important;
}

// .SerchInput {
//     // background: $blackColor!important;
//     // border: 1px solid $brownColor!important;
// }
.CircularProgressRed {
  color: $red !important;
  border-radius: 50px;
}

.CircularProgressGreen {
  color: $green !important;
  border-radius: 50px;
}

.CircularProgressLightGray {
  border-radius: 50px;
  color: $lightGray !important;
}

.CircularProgressOrange {
  color: $orange !important;
  border-radius: 50px;
}

.CircularProgressPrimary {
  color: $primaryColor !important;
  border-radius: 50px;
}

.CircularProgressBrownColor {
  color: $brownColor !important;
  border-radius: 50px;
}

.bg-green {
  background: $green !important;
}

body {
  font-family: "Roboto Slab", "Times New Roman", serif !important;
  // background: $blackColor!important;
  overflow: hidden !important;
}

.bg-lightGray {
  background: $lightGray !important;
}

.bg-primary {
  background: $primaryColor !important;
}

.color-lightGray {
  color: $lightGray !important;
}

.btn-black {
  background: $blackColor !important;
  color: white !important;
  border-radius: 20px !important;
  margin: 14px 0px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: $primaryColor !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0px !important;
}

.menu {
  // background: #ffffff!important;
  background: linear-gradient(#f0f0f0, #ffffff) !important;
  /* box-shadow: 1px 1px 20px!important; */
}

.main {
  background: #ffffff;
  /* box-shadow: 1px 1px 20px; */
  /* border-radius: 10px; */
  margin-top: 0px !important;
}

.LeftBar {
  background: #f0f0f0 !important;
}

.Navlist .MuiListItemIcon-root .MuiSvgIcon-root,
.Navlist a .MuiListItemText-root .MuiTypography-root {
  font-size: 15px !important;
  color: $blackColor !important;
  font-weight: 600;
}

.NavlistActive,
.NavlistActive a .MuiListItemText-root .MuiTypography-root {
  font-size: 15px !important;
  color: #877b6f !important;
  font-weight: 600;
  background: white !important;
}

.NavlistActive .MuiListItemIcon-root .MuiSvgIcon-root {
  background: rgba(255, 255, 255, 0) !important;
  color: #877b6f !important;
}

.Navlist .MuiListItemIcon-root {
  min-width: 20px !important;
}

.pageTitle {
  color: #3c4858;
  margin: 1.75rem 0 0.875rem;
  margin-top: 16px;
  min-height: 32px;
  font-family: "Roboto Slab", "Times New Roman", serif;
  font-weight: 700;
  margin-bottom: 1rem;
  text-decoration: none;
  font-size: x-large;
}

.pageContainer {
  padding: 15px !important;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgb(109, 107, 107);
  border-radius: 10px;
}

/* width */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  opacity: 0.5;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: $primaryColor;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: $primaryColor;
  opacity: 1;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: $primaryColor !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: $brownColor !important;
}

.MuiIconButton-root {
  border-radius: 10px !important;
  font-size: 0.8rem !important;
}

.MuiBadge-badge {
  font-size: 0.6em !important;
}

.MuiAvatar-root {
  width: 30px !important;
  height: 30px !important;
}

// height: 30px!important;
// }
@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 80px !important;
  }
}

// .MuiTabs-indicator {
//     min-width: 100px!important;
// }
.progressBarHelper {
  display: flex !important;
  align-content: center !important;
  justify-content: center !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
}

.list-subheader {
  font-size: 10px !important;
  font-weight: 900 !important;
}

.NavlistActive .MuiTypography-body1 {
  font-size: 15px !important;
  line-height: 1.5 !important;
  font-weight: bold !important;
  color: #3a3630;
}

.LeftBar .MuiTypography-body1 {
  font-weight: 900 !important;
  font-size: 15px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
  color: #3a3630;
  letter-spacing: 0.00938em;
}

.LeftBar .NavlistActive .MuiTypography-body1 {
  font-size: 15px !important;
  color: #877b6f !important;
  font-weight: 600;
  font: Gotham;
  background: rgba(255, 255, 255, 0) !important;
}

.LeftBar a:hover,
.LeftBar a:focus {
  color: #615e59 !important;
}

.LeftBar a {
  color: #ffffff !important;
}

.MuiDialogContent-dividers {
  padding: 0px 0px !important;
}

.MuiDialogContent-dividers .MuiListItemIcon-root {
  min-width: 35px !important;
}

.MuiDialogContent-dividers .MuiListItemText-root .MuiTypography-body1 {
  font-size: 12px !important;
}

.MuiDialogContent-dividers .MuiListItemIcon-root .MuiSvgIcon-root {
  font-size: 1rem !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

input:disabled,
.Mui-disabled {
  cursor: not-allowed !important;
}

.bell-icon .MuiBadge-badge {
  background-color: $primaryColor !important;
  color: $blackColor !important;
  font-weight: 600 !important;
}

.App-Settings .MuiFormControlLabel-root .MuiTypography-body1 {
  font-size: 14px !important;
  color: #3a3630 !important;
}

.searchForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-end;
  float: right;
}

.dialog-order .MuiTab-wrapper {
  font-size: 14px !important;
  text-transform: uppercase !important;
  font-weight: 900 !important;
}

.dialog-order .Mui-selected {
  color: $blackColor !important;
}

.curser-pointer {
  cursor: pointer;
}

a[disable="true"] .MuiButtonBase-root {
  cursor: not-allowed !important;
}

.datatable .MuiDataGrid-root .MuiDataGrid-viewport,
.MuiDataGrid-root .MuiDataGrid-viewport {
  overflow: initial !important;
}

.bg-red {
  background-color: $red !important;
}

.line-height-12 {
  line-height: 1.2 !important;
}

.btn-close {
  border-radius: 50px !important;
  font-weight: 900 !important;
}

.MuiDialogActions-root {
  padding: 8px 24px !important;
  margin-bottom: 10px !important;
}

.line-height-1-2 {
  line-height: 1.2 !important;
}

.status {
  // background-color: #ffa865;
  float: left !important;
  line-height: initial !important;
  padding: 6px !important;
  color: white !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  white-space: break-spaces;
  width: auto;
  text-align: center;
}

.status-new {
  background-color: #93c47d !important;
}

.status-marked-released {
  background-color: #a4c2f4 !important;
}

.status-marked-cancelled,
.status-cancelled {
  background-color: #cc0000 !important;
}

.status-completed {
  background-color: #34a853 !important;
}

.status-scheduled-date-confirmed {
  background-color: #f9cb9c !important;
}

.MuiTooltip-tooltip {
  color: white !important; //#3a3630!important;
  padding: 8px 8px !important;
  font-size: 0.625rem !important;
  max-width: 300px !important;
  word-wrap: break-word !important;
  font-weight: 100 !important;
  line-height: 1.5 !important;
  border-radius: 4px !important;
  background-color: $blackColor !important; //rgb(255, 255, 255)!important;
  text-align: justify !important;
  box-shadow: 0px 0px 6px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.MuiTooltip-arrow {
  color: $blackColor !important;
  font-size: 20px !important;
}

.quick-filter {
  justify-content: space-between !important;
}

@media only screen and (max-width: 600px) {
  .d-none-xs {
    display: none;
  }
  .pageTitle {
    margin-bottom: 0px !important;
  }
  .full-width-xs {
    width: 100% !important;
  }
  .m-0-xs {
    margin: 0 !important;
  }
  .quick-filter-order-page .quick-filter-md-9,
    .quick-filter-order-page .quick-filter-md-3 // .quick-filter-md-9 .MuiFormControl-root
  {
    display: block !important;
    width: 100%;
  }
  .quick-filter-md-3 {
    display: flex !important;
    justify-content: flex-end;
  }
  .form-search {
    justify-content: flex-end;
    margin-right: 0px !important;
  }
  .quick-filter-md-9 .MuiFormControl-root,
  .btn-clear {
    display: inline-flex !important;
  }
  .quick-filter-order-page .QuickFiltration::after {
    content: "\a";
    white-space: pre;
  }
  .Order-Popup .order-note {
    display: block !important;
  }
  .MuiDialog-paperFullWidth {
    height: calc(100% - 64px) !important;
  }
  .MuiDialog-paperScrollPaper {
    height: calc(100% - 64px) !important;
  }
  .orderPopup-height {
    height: calc(100% - 70px) !important;
    overflow: hidden !important;
  }
}

.MuiButton-root {
  border-radius: 12px !important;
}

.audit-log-datagrid .MuiDataGrid-columnHeaderWrapper {
  background: inherit !important;
  color: inherit !important;
  opacity: inherit !important;
}

.audit-log-datagrid .MuiDataGrid-columnsContainer {
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.Dispatch-Page-row-td .MuiButton-root,
.action-btn-div .MuiButton-root {
  border-radius: 6px !important;
}

.btn-export-import {
  height: 25px;
  padding: 0 10px !important;
  margin-right: 10px !important;
  border-radius: 6px !important;
}

.btn-black {
  background: #3a3630 !important;
  color: white !important;
  border-radius: 20px !important;
  margin: 14px 0px !important;
}

.App-Settings .MuiFormControlLabel-root {
  margin-right: 3px !important;
}

.contact-details-label {
  margin-bottom: 0px !important;
  font-size: 10px !important;
  align-items: flex-start !important;
  line-height: 1 !important;
}

a:hover {
  text-decoration: none !important;
}

.infoIcon {
  margin-right: 10px;
}

.order-event-logged-by {
  font-size: 12px;
  background-color: #ffa865 !important;
  float: left !important;
  line-height: initial !important;
  padding: 6px !important;
  color: white !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.order-event-logged-by.order-event-logged-by-bjs {
  background: #f8c808 0% 0% no-repeat padding-box !important;
  color: white !important;
}

.order-event-logged-by.order-event-logged-by-system {
  background: #3a36301a 0% 0% no-repeat padding-box !important;
  color: #3a3630 !important;
}

.order-event-logged-by.order-event-logged-by-shopify {
  background: #f8c808 0% 0% no-repeat padding-box !important;
  color: white !important;
}

.order-event-logged-by.order-event-logged-by-user {
  background: #3a36301a 0% 0% no-repeat padding-box !important;
  color: #3a3630 !important;
}

.error-handle-custom-class .react-confirm-alert .react-confirm-alert-body {
  text-align: center;
  max-width: 400px !important;
}

.error-handle-custom-class
  .react-confirm-alert
  .react-confirm-alert-body
  .react-confirm-alert-button-group {
  display: block;
}

.error-handle-custom-class
  .react-confirm-alert
  .react-confirm-alert-body
  .react-confirm-alert-button-group
  button {
  margin: 0px !important;
}

.error-handle-custom-class
  .react-confirm-alert
  .react-confirm-alert-body
  h1
  svg {
  font-size: 100px;
  color: red;
}

#main-section {
  margin-top: 10px !important;
}

// Layout 2
header.menu {
  font-family: $FontFamilyGotham;
  .menu-link {
    @include set-font;
    padding: 5px 15px !important;
    border-left: 1px solid #dbd4c488 !important;
    border-radius: 0px !important;
    color: $blackColor !important;
  }
  .store-dropdown {
    border-left: 0px solid #918a7b88 !important;
    margin-left: 0px;
    .store-switch-icon {
      margin-left: 10px;
    }
    .store-switch-icon.open {
      margin-left: 10px;
      transform: rotateZ(180deg);
    }
  }
  .store-dropdown.open {
    // filter: blur(0.2px);
    color: $blackColor;
    opacity: 0.5;
  }
  .sign-out {
    &.mobile {
      padding: 5px 0px 5px 15px !important;
      display: none;
      .box-arrow {
        margin-bottom: 0px !important;
      }
    }
    color: $blackColor !important;
    font-family: $FontFamilyGotham;
    .box-arrow {
      padding-right: 24px;
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      .arrow {
        position: absolute;
        left: 22px;
        transition: left 0.5s;
      }
    }
  }
  .sign-out:hover {
    .arrow {
      path {
        fill: $primaryColor;
        // stroke: blue;
        // stroke-width: 30px;
      }
      left: 26px;
    }
    .box {
      path {
        // stroke: black;
        // stroke-width: 30px;
        width: 20px;
      }
    }
  }
  .menu-link:hover,
  .menu-link:active {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .exit {
    background: #2b3134;
    color: white !important;
    margin: 0 10px;
    border-radius: 6px !important;
    img {
      width: 16px;
      margin-right: 10px;
      margin-bottom: 6px;
    }
    .text-line {
      position: relative;
      width: 100%;
      .line {
        width: 0px;
        height: 2px;
        background: $primaryColor;
        float: none;
        border-radius: 50px;
        position: absolute;
        bottom: 0;
        left: 0px;
        transition: width 0.5s;
        top: 15px;
      }
    }
  }
  .exit:hover {
    background: #2b3134 !important;
    color: white !important;
    .text-line .line {
      width: 26px;
    }
  }
  .help {
    img {
      width: 16px;
      margin-right: 10px;
      margin-bottom: 6px;
    }
    .text-line {
      position: relative;
      width: 100%;
      .line {
        width: 0px;
        height: 2px;
        background: $primaryColor;
        float: none;
        border-radius: 50px;
        position: absolute;
        bottom: 0;
        left: 0px;
        transition: width 0.5s;
        top: 15px;
      }
    }
  }
  .help:hover .text-line .line {
    width: 26px;
  }
  .live-staging {
    &.mobile {
      display: none;
      align-items: center;
      border-left: 1px solid #dbd4c488 !important;
      .button {
        // padding: 5px 5px !important;
        width: auto;
      }
    }
    border-left: 0px solid #918a7b !important;
    .button {
      font-family: $FontFamilyGotham !important;
      text-transform: capitalize;
      width: 120px;
      border-radius: 6px !important;
      color: #ffffff;
      font-weight: 900 !important;
      justify-content: space-between;
      font-size: 12px !important;
      span {
        line-height: 1.5;
      }
    }
    .production {
      background: #6396e5 0% 0% no-repeat padding-box !important;
    }
    .staging {
      background: #e95252 0% 0% no-repeat padding-box !important;
    }
  }
}

.LeftBar {
  .MuiTypography-body1 {
    text-align: left;
    font: Gotham;
    font-size: 13px !important;
    font-weight: 600 !important;
    letter-spacing: 0px;
    color: #888075;
    opacity: 1;
  }
  .MuiListSubheader-root {
    font-family: Eveleth !important;
    color: #000000;
    opacity: 0.25;
    font-size: 10px !important;
  }
  .Navlist {
    .navbar-count {
      background: #e7e6e6;
      padding: 0px 8px;
      position: absolute;
      right: 15px;
      border-radius: 50px;
      font-size: 10px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $FontFamilyGotham;
      span {
        padding-top: 2px;
      }
    }
    .MuiListItemText-primary {
      font-weight: 500 !important;
      font-family: $FontFamilyGotham !important;
    }
    &.NavlistActive {
      .MuiTypography-body1 {
        font-size: 13px !important;
        color: $blackColor !important;
        font-weight: 600;
        font-family: Gotham-Bold !important;
        background: rgba(255, 255, 255, 0) !important;
      }
      path {
        fill: black !important;
      }
      .navbar-count {
        background: #e7e6e6;
      }
      .MuiListItemText-primary {
        font-weight: 800 !important;
      }
    }
    &:hover {
      background: #34a85300;
      .MuiTypography-body1 {
        color: $blackColor !important;
      }
      path {
        fill: black !important;
      }
    }
  }
}

.store-selected-menu {
  .MuiMenuItem-root {
    font-family: $FontFamilyGotham !important;
  }
  .store-icon {
    margin-right: 10px;
  }
}

.pagination {
  svg {
    height: 10px;
  }
  button {
    border-radius: 50px !important;
    // padding: 10px 15px;
    margin: 0px 5px;
    &[disabled] {
      opacity: 0.5;
    }
  }
  .main-span {
    margin: 0px 10px;
    font-size: 12px;
    color: #000000 !important;
    span {
      margin: 0px 5px;
      opacity: 0.5;
    }
  }
}

.search-bar {
  position: relative;
  // width: 100%;
  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 2px solid #d7d6d5 !important;
    }
  }
  .search-box {
    position: absolute;
    left: -500px;
    width: 480px;
    input::placeholder {
      color: $blackColor;
      font: normal normal normal 12px Gotham;
      opacity: 1;
      display: flex;
      /* Firefox */
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $blackColor;
      font: normal normal normal 12px Gotham;
      opacity: 1;
      display: flex;
    }
    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $blackColor;
      font: normal normal normal 12px Gotham;
      opacity: 1;
      display: flex;
    }
    .MuiOutlinedInput-root {
      border-radius: 30px;
      background-color: #ffffff;
      box-shadow: 0px 0px 1px black;
    }
  }
  button {
    border-radius: 50px !important;
    padding: 10px !important;
    width: 40px !important;
    height: 40px !important;
    &.search-btn {
      background: #3a3630 !important;
      &:hover {
        background: #feba30 !important;
        & path {
          fill: black !important;
        }
      }
    }
    &.search-btn-inside-text-box {
      path {
        fill: black !important;
      }
    }
  }
}

.order-confirm-popup {
  background: transparent linear-gradient(180deg, #3a3630e0 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  z-index: 999999 !important;
  .react-confirm-alert {
    width: 600px;
    // height: 450px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #0000000d;
    border-radius: 20px;
    opacity: 1;
    position: relative;
    button.close-icon {
      right: 10px;
      top: 10px;
      position: absolute;
      svg {
        width: 30px;
        height: 30px;
        path {
          fill: #000000 !important;
          opacity: 0.3;
        }
      }
      &:hover {
        path {
          opacity: 0.6;
        }
      }
    }
    .popup {
      padding: 50px;
      height: 100%;
      text-align: center;
      svg {
        width: 100px;
        height: 100px;
        &.cancel {
          path {
            fill: #dc6988 !important;
          }
        }
        &.delete {
          path {
            fill: #e95252 !important;
          }
        }
      }
      h1 {
        text-align: center;
        font: normal normal normal 26px/36px Eveleth;
        letter-spacing: 0px;
        color: #3a3630;
        opacity: 1;
      }
      .related-order {
        // max-height: 100px !important;
        overflow: auto !important;
        float: left !important;
      }
      .order-related-orders {
        max-height: 200px !important;
        width: 100%;
        overflow: auto !important;
        float: left !important;
        width: 84%;
        margin: 0 calc(8%);
      }
      p {
        text-align: center;
        font: normal normal normal 13px Gotham;
        letter-spacing: 0px;
        color: #3a3630;
        &.order-no {
          font-style: italic !important;
          font-weight: 900 !important;
          line-height: 1.3;
          float: left;
          width: 100%;
          white-space: break-spaces;
        }
        .orders-list {
          // height: 100px;
          // overflow-y: auto;
          // overflow-y: ;
          // -webkit-column-count: 3;
          // -moz-column-count: 3;
          // column-count: 3;
          float: left;
          width: 100%;
          li {
            text-align: start;
            width: 33%;
            float: left;
            margin: auto;
          }
        }
        &.are-you-sure {
          float: left;
          width: 100%;
          margin: 30px 0px !important;
        }
      }
      .MuiDialogActions-spacing {
        align-items: center;
        justify-content: center;
        margin-top: 20px !important;
        margin-bottom: 10px !important;
        white-space: nowrap;
        button {
          background: #3a3630 0% 0% no-repeat padding-box;
          border-radius: 66px;
          &:hover {
            .MuiButton-label {
              color: #3a3630 !important;
            }
            background: $primaryColor;
          }
          .MuiButton-label {
            text-align: center;
            font: normal normal normal 12px Eveleth !important;
            letter-spacing: 0px !important;
            color: #ffffff !important;
            opacity: 1 !important;
            padding: 10px 30px;
          }
          border-radius: 50px !important;
          &.button-cancel {
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid $blackColor;
            opacity: 0.5;
            transition: 1s opacity;
            &:hover {
              opacity: 1;
            }
            .MuiButton-label {
              color: $blackColor !important;
              padding: 10px 20px;
            }
            &.failure {
              .MuiButton-label {
                color: #ffffff !important;
              }
            }
          }
        }
      }
    }
  }
}

.MuiDrawer-paper {
  z-index: 50 !important;
}

.order-response-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: inline-flex;
  justify-content: center !important;
  z-index: 999999 !important;
  // align-items: flex-start;
  // background: transparent linear-gradient(180deg, #3a3630e0 0%, #000000 100%) 0% 0% no-repeat padding-box!important;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: center;
  .popup {
    margin-top: 20px;
    width: 500px;
    background: transparent linear-gradient(118deg, #b6dc69 0%, #57db7a 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #00000029;
    border-radius: 10px;
    position: relative;
    display: flex !important;
    justify-content: flex-start !important;
    button.close-icon {
      z-index: 1;
      right: 10px;
      top: 10px;
      position: absolute;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: #000000 !important;
          opacity: 0.3;
        }
      }
      &:hover {
        path {
          opacity: 0.6;
        }
      }
    }
    .popup-body {
      width: 500px;
      padding: 10px 50px 25px 70px;
      height: 100%;
      text-align: left;
      position: relative;
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      h1 {
        position: absolute;
        margin: 0px;
        text-align: left;
        font: normal normal normal 14px/16px Eveleth;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        border-left: 3px solid;
        padding: 20px 10px;
        align-items: center;
        display: flex;
        left: 15px;
        top: 15px;
        svg {
          margin-right: 10px;
          height: 30px;
          width: 30px;
        }
      }
      .popup-content {
        max-height: 150px;
        overflow: auto;
        margin-top: 70px;
        white-space: break-spaces;
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        margin-top: 70px;
        p {
          font: normal normal normal 12px Gotham;
          letter-spacing: 0px;
          color: #000000;
          &.order-no {
            line-height: 1.5;
            font-style: italic !important;
            font-weight: 900 !important;
          }
        }
        .MuiDialogActions-spacing {
          align-items: center !important;
          justify-content: flex-start !important;
          margin-top: 20px !important;
          margin-bottom: 10px !important;
          padding: 0px !important;
          button {
            background: #3a3630 0% 0% no-repeat padding-box;
            border-radius: 66px;
            .MuiButton-label {
              text-align: center;
              font: normal normal normal 12px Eveleth !important;
              letter-spacing: 0px !important;
              color: #ffffff !important;
              opacity: 1 !important;
              padding: 10px 30px;
            }
            border-radius: 50px !important;
            &.button-cancel {
              background: #ffffff00 0% 0% no-repeat padding-box;
              border: 2px solid #000000;
              opacity: 1;
              .MuiButton-label {
                color: #3a3630 !important;
                padding: 5px 20px;
              }
              &.failure {
                .MuiButton-label {
                  color: #ffffff !important;
                }
              }
            }
          }
        }
      }
    }
    &.popup-failure {
      background: transparent linear-gradient(118deg, #dc69ae 0%, #e95252 100%)
        0% 0% no-repeat padding-box;
      button.close-icon {
        svg {
          path {
            fill: #ffffff !important;
          }
        }
      }
      .popup-body {
        h1 {
          color: #ffffff;
          opacity: 1;
          border-left: 3px solid;
          padding: 20px 10px;
          align-items: center;
          display: flex;
          left: 15px;
          svg {
            margin-right: 10px;
            height: 30px;
            width: 30px;
          }
        }
        .popup-content {
          max-height: 150px;
          overflow: auto;
          margin-top: 70px;
          white-space: break-spaces;
          p {
            font: normal normal normal 12px Gotham;
            letter-spacing: 0px;
            color: #ffffff;
            &.order-no {
              font-style: italic !important;
              font-weight: 900 !important;
            }
          }
          .MuiDialogActions-spacing {
            align-items: center;
            justify-content: flex-start;
            padding: 0px !important;
            margin-top: 20px !important;
            margin-bottom: 10px !important;
            button {
              background: #ffffff 0% 0% no-repeat padding-box;
              border-radius: 66px;
              .MuiButton-label {
                text-align: center;
                font: normal normal normal 12px Eveleth !important;
                letter-spacing: 0px !important;
                color: #000000 !important;
                opacity: 1 !important;
                padding: 10px 30px;
              }
              border-radius: 50px !important;
              &.button-cancel {
                background: #ffffff00 0% 0% no-repeat padding-box;
                border: 2px solid #ffffff;
                opacity: 1;
                .MuiButton-label {
                  color: #000000 !important;
                  padding: 5px 20px;
                }
                &.failure {
                  .MuiButton-label {
                    color: #ffffff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $medium) {
  .order-response-popup {
    .popup {
      width: calc(100% - 20px);
      // .popup-body {
      // }
    }
  }
}

.check-box {
  position: relative;
  border: 1.2px solid #d2d1cf;
  background: #ffffff 0% 0% no-repeat padding-box;
  left: 0px;
  padding: 7px;
  float: left;
  border-radius: 3px;
  cursor: pointer;
  &.active {
    border: 1.2px solid #888075e3;
  }
  &.disabled {
    cursor: not-allowed;
    background: lightGray;
  }
  &:not(.disabled):hover {
    border: 1.2px solid #888075e3;
  }
  input {
    display: none;
  }
  svg {
    &.check {
      cursor: pointer;
      width: 13px;
      height: 13px;
      position: absolute;
      left: 2px;
      top: -2px;
    }
  }
}

.footer {
  text-align: center;
  font-family: Gotham;
  color: #3a3630;
  font-size: 12px;
  margin-top: 20px !important;
  margin-right: -8px;
  margin-left: -8px;
  padding: 20px !important;
  border-top: 1px solid #f0f0f0;
  float: left;
  width: calc(100% + 16px);
  position: relative;
  opacity: 0.6;
  a {
    color: #000000;
    text-decoration: underline;
    &:hover {
      color: #000000;
      text-decoration: none;
    }
  }
  .back-to-top {
    position: absolute;
    right: 100px;
    top: -22px;
    background: #ffffff;
    z-index: 2;
    border-radius: 50px;
    border: 1px solid #e6e4e4;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    svg {
      position: absolute;
      display: flex;
      height: 15px;
      transition: top 1s, height 1s;
      path {
        transition: fill 1s;
        fill: #888682;
      }
      &.line {
        top: 10%;
      }
      &.arrow {
        top: 40%;
      }
    }
    &:hover {
      svg {
        &.arrow {
          top: 30%;
          path {
            fill: #3a3630;
          }
        }
        &.line {
          top: -5%;
          height: 25px;
          path {
            fill: $primaryColor;
          }
        }
      }
    }
  }
}

li.sorting {
  display: flex !important;
  justify-content: space-between !important;
}

svg.delete-icon {
  path {
    fill: #e95252;
  }
}

.Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #3a3630 !important;
  }
}

td {
  // &:hover {
  //     background: #FFFFFF;
  // }
  .no-result {
    padding: 100px 0px;
    display: flex;
    align-items: center;
    .icon {
      text-align: end;
      padding-right: 80px;
    }
    h1 {
      white-space: break-spaces;
      text-align: left;
      font: normal normal 950 40px/60px Eveleth;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
    }
    p {
      white-space: break-spaces;
      text-align: left;
      font: normal normal normal 14px/18px Gotham;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
    }
    hr {
      margin: 30px 0px;
      background: #d7d6d5;
      border: 0px solid #d7d6d5;
      border-radius: 5px;
      padding: 1px;
    }
    h4 {
      white-space: break-spaces;
      text-align: left;
      font: normal normal normal 14px/24px Gotham-Bold;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
    }
    .icon {
      text-align: end;
    }
  }
}

.page-loader {
  // .MuiBackdrop-root {
  background: transparent linear-gradient(180deg, #3a363031 0%, #00000050 100%)
    0% 0% no-repeat padding-box !important;
  // opacity: 0.1!important;
  //     // .MuiPaper-root {
  .MuiPaper-elevation {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    // box-shadow: 0px 0px 50px #ff0000 !important;
    border-radius: 20px !important;
    opacity: 1 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px 60px;
  }
  button.close-icon {
    right: 10px;
    top: 10px;
    position: absolute;
    svg {
      width: 30px;
      height: 30px;
      path {
        fill: #000000 !important;
        opacity: 0.3;
      }
    }
    &:hover {
      path {
        opacity: 0.6;
      }
    }
  }
  // }
}

.custom-loader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  img {
    height: 100px;
    width: 100px;
  }
  h1 {
    text-align: center;
    font: normal normal 950 20px/28px Eveleth;
    letter-spacing: 0px;
    color: #3a3630;
    opacity: 0.7;
  }
  p {
    text-align: center;
    font: normal normal normal 14px/24px Gotham;
    letter-spacing: 0px;
    color: #75726e;
    opacity: 1;
  }
}

.order-response-popup
  .popup.popup-failure
  .popup-body
  .popup-content
  .MuiDialogActions-spacing
  button.button-cancel.failure
  .MuiButton-label {
  color: #ffffff !important;
}

div.datatable {
  // overflow: auto;
  .wrap {
    position: relative;
    // margin: 10em auto 30em;
    max-width: 1024px;
    overscroll-behavior: contain;
  }
  .headers {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
  }
  .tracks,
  .scroller {
    display: flex;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .scroller {
    overflow-x: hidden;
  }
  .tracks {
    overflow: auto;
    scroll-snap-type: x mandatory;
  }
  .scenes::-webkit-scrollbar,
  .scroller::-webkit-scrollbar {
    display: none;
  }
  .track {
    flex: 1 0 calc(22% + 2px);
    scroll-snap-align: start;
  }
  .track + .track {
    margin-left: -1px;
  }
  .heading {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border: solid #fff;
    border-width: 0 1px;
    z-index: 1;
    background: #efefef;
    font-weight: 700;
  }
  .entry {
    border: 1px solid #ebebeb;
    border-top: 0;
    background: #fff;
    height: 8em;
    padding: 1em;
  }
  @media (max-width: 767px) {
    .track {
      flex: 1 0 calc(50% + 7px);
    }
  }
}

#parent-main-section {
  overflow-x: auto;
  // #main-section {
  //   min-width: 1200px;
  // }
}

.quick-filter-checkbox {
  display: flex;
  align-items: center;
  label {
    line-height: 1;
    margin-top: 5px;
  }
}

.count-pagination-searched-result {
  margin: 0px 14px;
}

.order-note-icon {
  svg {
    circle {
      fill: $primaryColor;
    }
    path {
      fill: #00000080;
    }
  }
}

.action-bar.MuiIconButton-root.Mui-disabled {
  background-color: darkgray !important;
  opacity: 0.5;
}

.pagination button {
  height: 20px !important;
}

.count-pagination-searched-result .record-count {
  margin-top: 0px !important;
}

.page-title-grid {
  .search-box-mobile {
    display: none;
  }
}

@media screen and (max-width: $medium) {
  .footer .back-to-top {
    right: 50px;
  }
  .order-action {
    padding: 0 !important;
  }
  .page-loader .MuiPaper-elevation {
    max-height: 400px;
  }
  .page-title-grid {
    position: relative;
    .search-box-mobile {
      display: block;
      position: absolute;
      right: 15px;
      top: 20px;
      .search-bar .search-box {
        position: absolute;
        left: calc(-290px - 100%);
        width: calc(350px - 100%);
      }
    }
  }
  .order-confirm-popup {
    .react-confirm-alert {
      max-width: calc(100% - 20px);
      .popup {
        padding: 50px 30px;
        .order-related-orders {
          width: 100%;
          margin: 0 0;
        }
      }
    }
  }
}
